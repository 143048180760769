import { useEffect } from 'react';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import Portal from 'components/Portal/Portal';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import {
  injectRegistrationAnalyticsData,
  parseHTML,
} from 'lib/utils/analytics';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetSiteTrackingSettingsQuery } from 'redux/services/siteInfo';
import { useGetTeamRepLabelQuery } from 'redux/services/staffRoleApi';
import { useAppSelector } from 'redux/store';
import PlayAppLockup from 'domains/Checkout/PlayAppLockup/PlayAppLockup';
import TransactionConfirmationCard from 'domains/Checkout/TransactionConfirmationCard/TransactionConfirmationCard';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import ConfirmationRollup from '../ConfirmationRollup/ConfirmationRollup';
import styles from './ConsolidatedConfirmationPage.module.scss';
import * as S from './ConsolidatedConfirmationPage.styles';

/* ConsolidatedConfirmationPage */
export default function ConsolidatedConfirmationPage() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: teamRepLabel = '' } = useGetTeamRepLabelQuery({
    siteId: siteId ?? '',
  });
  const { data: siteTrackingSettings } = useGetSiteTrackingSettingsQuery(
    { siteId: siteId ? parseInt(siteId) : -1 },
    { skip: !siteId }
  );

  const { registrationAnalyticsData } = useCheckoutInfo();

  useEffect(() => {
    if (registrationAnalyticsData) {
      registrationAnalyticsData.forEach(injectRegistrationAnalyticsData);

      if (siteTrackingSettings?.conversionTrackingRegistration) {
        document.body.append(
          parseHTML(siteTrackingSettings.conversionTrackingRegistration)
        );
      }
    }
  }, [registrationAnalyticsData, siteTrackingSettings]);

  useEffect(() => {
    document.title = siteName ? `${siteName}: Confirmation` : 'Confirmation';
  }, [siteName]);

  const transactionConfirmationBody = teamRepLabel
    ? [
        `Almost there! Your assigned ${teamRepLabel}(s) will receive an invitation and further instructions.`,
        "To check on a team's registration status, visit the Member Dashboard.",
      ]
    : [];

  return (
    <S.ConsolidatedConfirmationPage>
      <PageTitle>Thank you</PageTitle>
      <ColumnGrid>
        <ConfirmationMessage />
        <ConfirmationRollup />
        <TransactionConfirmationCard
          cardBody={transactionConfirmationBody}
          cardTitle="Tournament registration"
        />
      </ColumnGrid>
      <Portal parentId="#mp-footer-portal">
        <PlayAppLockup />
      </Portal>
    </S.ConsolidatedConfirmationPage>
  );
}
/* */

export function ConfirmationMessage() {
  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );
  const headSize = isTabletLandscapeUp ? 'large' : 'small';
  return (
    <div className={styles.confirmationMessage}>
      <S.ConfirmationHead customTag="h2" variant="headline" size={headSize}>
        You're all set!
      </S.ConfirmationHead>
      <S.MessageBody size="large" variant="ui">
        You will receive a confirmation email in your inbox shortly. If you have
        any questions, please contact the tournament administrator.
      </S.MessageBody>
    </div>
  );
}
