import {
  RegistrantInfoResponse,
  RegistrationDecodedParams,
  RegistrationSettings,
  RegistrationType,
  SelectRegistrationsResponse,
} from '@la/types';
import { CartItemData } from 'domains/Checkout/Checkout.types';
import baseApi from './baseApi';

export type RegistrationIds = {
  registrationId: string;
  registrationIdOg: number;
};
export type RegistrationIdsMap = Record<string, RegistrationIds>;

export type UpdateRegistrationsPayload = {
  programId: string;
  id?: string;
  teams: { id: string; name: string }[];
};

export type NGBMembershipType = {
  membershipType: 'USA_LACROSSE' | 'USA_HOCKEY';
  enabled: boolean;
  id: string;
};

export const registrationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationIds: builder.query<
      RegistrationIdsMap,
      { siteDomain: string; registrationIds: string[] }
    >({
      query: ({ siteDomain, registrationIds }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registrations`,
          params: {
            registrationIds,
          },
        };
      },
    }),
    updateRegistrations: builder.mutation<
      CartItemData,
      {
        siteDomain: string;
        programId: string;
        editURL: string;
        registrations: UpdateRegistrationsPayload[];
        includeRegistrationTriageFix?: boolean;
      }
    >({
      query: ({
        siteDomain,
        programId,
        editURL,
        registrations,
        includeRegistrationTriageFix,
      }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registration`,
          method: 'POST',
          body: {
            programId,
            editURL,
            registrations,
            includeRegistrationTriageFix,
          },
        };
      },
      invalidatesTags: ['CartSummary', 'Registration'],
    }),
    getRegistrationSettings: builder.query<
      RegistrationSettings,
      { programId: string; subdomain: string }
    >({
      query: ({ subdomain, programId }) => ({
        url: `/api/member-portal/${subdomain}/registrationSettings`,
        method: 'GET',
        params: {
          programId,
        },
      }),
    }),
    getExposeNGBMembershipFields: builder.query<
      string | null,
      { programId?: string; subdomain: string }
    >({
      query: ({ subdomain, programId }) => ({
        url: `/api/member-portal/${subdomain}/ngbVerifications/settings`,
        method: 'GET',
        params: {
          programId,
        },
      }),
      transformResponse: (resp: NGBMembershipType[]) => {
        return resp?.find((resp) => resp.enabled)?.membershipType ?? null;
      },
    }),
    validateNGBMembership: builder.mutation<
      null,
      {
        membershipCode: string;
        membershipType: string;
        playerId: string;
        programId: string;
        siteDomain: string;
      }
    >({
      query: ({
        membershipCode,
        membershipType,
        playerId,
        programId,
        siteDomain,
      }) => {
        return {
          url: `/api/member-portal/${siteDomain}/ngbVerifications/validate`,
          method: 'POST',
          body: {
            playerId,
            programId,
            membershipType,
            membershipCode,
          },
        };
      },
    }),
    getRegistrantInfo: builder.query<
      RegistrantInfoResponse,
      {
        decodedParams?: RegistrationDecodedParams | null;
        encodedToken?: string | null;
        registrationId: string | null;
        siteDomain: string;
      }
    >({
      query: ({ decodedParams, encodedToken, siteDomain, registrationId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registrations/registrant-info`,
          method: 'GET',
          params: {
            decodedParams: decodedParams
              ? JSON.stringify(decodedParams)
              : undefined,
            encodedToken: encodedToken ? encodedToken : undefined,
            registrationId: registrationId ?? undefined,
          },
        };
      },
      transformResponse: (response: RegistrantInfoResponse) => {
        if ((response.decodedData?.type as string) === 'player') {
          return {
            ...response,
            decodedData: {
              ...response.decodedData,
              type: RegistrationType.Player,
            },
          };
        }
        if ((response.decodedData?.type as string) === 'staff') {
          return {
            ...response,
            decodedData: {
              ...response.decodedData,
              type: RegistrationType.Staff,
            },
          };
        }
        return response;
      },
    }),
    getSelectRegistration: builder.query<
      SelectRegistrationsResponse,
      { siteDomain: string; tournamentId: string; userId: string }
    >({
      query: ({ siteDomain, tournamentId, userId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}/select-registration`,
          params: {
            tournamentId,
            userId,
          },
        };
      },
    }),
    finalizeRegistrations: builder.mutation<
      any,
      {
        siteDomain: string;
        programId: string;
        registrations: UpdateRegistrationsPayload[];
        type: RegistrationType;
        userId: number;
      }
    >({
      query: ({ registrations, siteDomain, type, userId, programId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/finalize-registrations`,
          method: 'POST',
          body: {
            registrations,
            type,
            userId,
            programId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetExposeNGBMembershipFieldsQuery,
  useLazyGetRegistrationIdsQuery,
  useGetRegistrationSettingsQuery,
  useUpdateRegistrationsMutation,
  useValidateNGBMembershipMutation,
  useGetSelectRegistrationQuery,
  useGetRegistrantInfoQuery,
  useFinalizeRegistrationsMutation,
} = registrationApi;
