import { useParams } from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetCountriesAndAdministrativeDivisionsQuery } from 'redux/services/countryApi';
import { useGetTeamsQuery } from 'redux/services/teamApi';
import { MainContent } from 'domains/Shell/MainContent/MainContent';
import { MainContentCenter } from 'domains/Shell/MainContent/MainContent.styles';
import { isWithinDateRange } from '../TournamentDetailsPage/utils/date';
import { TOURNAMENT_NOT_FOUND_ERROR_MESSAGE } from '../utils/errorMessages';
import { Wizard } from './Wizard/Wizard';
import { useTournamentForRegistration } from './hooks/useTournamentForRegistration';
import * as S from './Registration.styles';
import { WizardContent } from './Wizard/Wizard.styles';

export const API_ERROR_MESSAGE =
  'There was an error loading this page. Please try refreshing.';

export function Registration() {
  const { subdomain } = getLAHostnameParts();
  const { tournamentId } = useParams();

  if (!tournamentId) {
    throw new Error('The tournament ID is not defined');
  }

  const {
    tournament,
    isLoading: isTournamentLoading,
    error: tournamentError,
  } = useTournamentForRegistration({
    id: tournamentId,
  });

  /**
   * Perform the query here so we can handle the loading and error states, as
   * well as to cache the data for use in child components (CreateTeamForm).
   */
  const { isLoading: isCountriesLoading, isError: hasCountriesError } =
    useGetCountriesAndAdministrativeDivisionsQuery();

  const {
    data: teams = [],
    isLoading: isGetTeamsLoading,
    error: getTeamsError,
  } = useGetTeamsQuery({ siteDomain: subdomain });

  if (isTournamentLoading || isCountriesLoading || isGetTeamsLoading) {
    return (
      <MainContentCenter>
        <Loader description="We are gathering available divisions..." loading />
      </MainContentCenter>
    );
  }

  if (tournamentError || hasCountriesError || getTeamsError) {
    return (
      <MainContent>
        <WizardContent>
          <S.Error>
            <ErrorCard message={API_ERROR_MESSAGE} />
          </S.Error>
        </WizardContent>
      </MainContent>
    );
  }

  if (!tournament) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  const { registrationStartDate, registrationEndDate, timeZone } = tournament;
  if (
    tournament.divisions.length === 0 ||
    (registrationStartDate &&
      registrationEndDate &&
      !isWithinDateRange(registrationStartDate, registrationEndDate, timeZone))
  ) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  let hasRegistrationsToEdit = !!tournament?.divisions.find(
    (division) =>
      division.registrationsForUser &&
      division.registrationsForUser.find(
        (registration) => registration.registrationStatus === 'CREATED'
      )
  );

  return hasRegistrationsToEdit ? (
    <Wizard
      hasRegistrationsToEdit={hasRegistrationsToEdit}
      sectionTitle="Edit your divisions and teams"
      submitButtonText="Update cart"
      submitUnsavedChangesErrorMessage="Registrations were not updated in the cart. Unsaved changes must be saved or discarded."
      teams={teams}
      tournament={tournament}
    />
  ) : (
    <Wizard
      hasRegistrationsToEdit={hasRegistrationsToEdit}
      sectionTitle="Add your divisions and teams"
      submitButtonText="Add to cart"
      submitUnsavedChangesErrorMessage="Registrations were not added to the cart. Unsaved changes must be saved or discarded."
      teams={teams}
      tournament={tournament}
    />
  );
}
