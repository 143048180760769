import styled from 'styled-components';
import { CaretDownIcon, ThemedComponent } from '@la/ds-ui-components';
import { breakpointQueries } from 'lib/media-queries/breakpoints';

export const AccordionTriggerContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 16,
    '& label': {
      flex: 1,
      ...theme.typography.ui.large,
    },

    [`@media ${breakpointQueries.tabletLandscapeUp}`]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 8,
    },
  })
);

export const WaiversContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 92,
  maxWidth: 920,
  width: '100%',
});

export const CheckboxContainer = styled.div({
  display: 'flex',
  gap: 8,
});

export const CardBody = styled.div({
  padding: '12px 16px',
});

export const AccordionContentContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    backgroundColor: theme.semantic?.primary[35],
    padding: 24,
  })
);

export const AccordionContent = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    border: '1px solid',
    borderColor: theme.semantic?.primary[400],
    borderRadius: 4,
    backgroundColor: 'white',
    padding: 8,
    maxWidth: '100%',
  })
);

export const AccordionCaretContainer = styled.div<{ $isOpened: boolean }>(
  ({ $isOpened }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: $isOpened ? 'rotate(180deg)' : 'rotate(0)',
    transition: 'transform 120ms linear',
    willChange: 'transform',
  })
);

export const AccordionCaret = styled(CaretDownIcon).attrs(() => ({
  size: 'small',
  variant: 'filled',
}))({});

export const CheckboxWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

export const ErrorMessage = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    gap: 8,

    '& > p': {
      color: theme.palette.foundational.red[500],
    },

    '& > svg': {
      stroke: theme.palette.foundational.red[500],
    },
  })
);
