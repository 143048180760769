import { useMemo, useState } from 'react';
import {
  Select,
  SelectOption,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { Roster, Team as TeamType } from '@la/types';
import { Team } from '../Team/Team';
import {
  getTeamId,
  TeamFilters,
  TeamSelectionModalProps,
} from '../TeamSelectionModal';
import styles from './TeamList.module.scss';

export const NO_TEAMS_TEXT = 'You have no teams available.';

export type TeamListProps = {
  /**
   * All teams that the user is a staff member of.
   */
  allTeams?: TeamType[];
  /**
   * The currently displayed teams.
   */
  teams: TeamType[];
  /**
   * Triggered when a team is selected.
   * @param team The team being selected.
   */
  onTeamSelect: (team: TeamType) => void;
  /**
   * The currently selected team. Rendered separately from the rest of the teams.
   */
  selectedTeam: TeamSelectionModalProps['selectedTeam'];
} & TeamFilters;

const allOption = {
  value: 'all',
  label: 'All',
};

const sortOptions = [
  { label: 'Alphabetical', value: 'teamName' },
  { label: 'Created', value: 'createdOn' },
];

const programToSelectOptions = (
  programsNames: {
    programName: string | undefined;
    programId: string | undefined;
  }[]
): SelectOption[] => {
  const options: SelectOption[] = programsNames.map(
    ({ programId, programName }) => ({
      label: programName ?? '',
      value: programId?.toString() ?? '',
    })
  );

  return [allOption, ...options];
};

// Something is up with Sets for string arrays, probably related to our tsconfig.target
const getUniqueStrings = (arr: string[]): string[] => {
  const uniqueArray: string[] = [];

  for (const str of arr) {
    if (!uniqueArray.includes(str)) {
      uniqueArray.push(str);
    }
  }

  return uniqueArray;
};

const getProgramOptions = (teams: TeamType[]) => {
  const groupedProgramIds = teams
    .filter((team) => Boolean(team.groupedProgramId))
    .map((team) => `${team.groupedProgramId}`);

  const uniqueProgramIds = getUniqueStrings(groupedProgramIds);

  const mappedSubprograms = new Map<string, SelectOption[]>();

  for (const groupedProgramId of Array.from(uniqueProgramIds)) {
    const subprograms = teams
      .filter(
        (team) =>
          !team.groupedProgramId ||
          team.groupedProgramId.toString() === groupedProgramId
      )

      .map((team) => ({
        programName: team.programName,
        programId: team.programId ? team.programId.toString() : '',
      }));

    const uniqueSubprograms = getUniqueStrings(
      subprograms.map((program) => program.programId)
    );

    mappedSubprograms.set(
      `${groupedProgramId}`,
      programToSelectOptions(
        uniqueSubprograms.map((programId) => {
          return {
            programId: programId,
            programName: subprograms.find(
              (subprogram) => subprogram.programId === programId
            )?.programName,
          };
        })
      )
    );
  }

  const groupedProgramsOptions = programToSelectOptions(
    Array.from(uniqueProgramIds).map((groupedProgramId) => ({
      programId: groupedProgramId?.toString(),
      programName: teams.find(
        (team) => team.groupedProgramId?.toString() === groupedProgramId
      )?.groupedProgramName,
    }))
  );

  return {
    groupedProgramsOptions,
    mappedSubprograms,
  };
};

export const TeamList = ({
  allTeams,
  teams,
  onTeamSelect,
  selectedTeam,
  setTeamName,
  teamName,
  programId,
  setProgramId,
  subprogramId,
  setSubprogramId,
  sort,
  setSort,
}: TeamListProps) => {
  const { mappedSubprograms, groupedProgramsOptions } = useMemo(
    () => getProgramOptions(allTeams ?? teams),
    [allTeams, teams]
  );
  const [allGroupedProgramsOptions] = useState(groupedProgramsOptions);

  const onChangeNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value);
  };

  return (
    <ul className={styles.teamListContainer}>
      <div>
        <TextInput
          id="filter-team-name"
          label="Team name"
          placeholder="Enter Team Name"
          value={teamName}
          onChange={onChangeNameFilter}
        />
        <div className={styles.selectionFiltersContainer}>
          <div className={styles.selectionFilter}>
            <Select
              hidePlaceholder
              id="filter-grouped-program-name"
              label="Program"
              options={allGroupedProgramsOptions}
              placeholder="Select a program"
              value={programId}
              onChange={setProgramId}
              size="medium"
            />
          </div>

          <div className={styles.selectionFilter}>
            <Select
              hidePlaceholder
              id="filter-subprogram-name"
              label="Subprogram"
              options={
                (mappedSubprograms.get(programId) as SelectOption[]) ?? []
              }
              placeholder="Select a subprogram"
              value={subprogramId}
              onChange={setSubprogramId}
              size="medium"
              disabled={
                !Boolean(programId && mappedSubprograms.get(programId)?.length)
              }
            />
          </div>
          <div className={styles.selectionSort}>
            <Select
              hidePlaceholder
              placeholder="Sort"
              id="sort-teams"
              label="Sort"
              options={sortOptions}
              value={sort}
              onChange={(value) => setSort(value as 'createdOn' | 'teamName')}
              size="medium"
            />
          </div>
        </div>
      </div>
      {teams.length > 0 ? (
        <div className={styles.teamItems}>
          {selectedTeam ? (
            <li
              className={styles.selectedTeam}
              data-testid={getTeamId(
                selectedTeam.teamInfo.teamIdOg,
                selectedTeam.programId!
              )}
            >
              <Team selected team={transformRosterToTeam(selectedTeam)} />
            </li>
          ) : null}
          <FilteredTeamList
            teamList={teams}
            onTeamSelect={onTeamSelect}
            selectedTeam={
              selectedTeam ? transformRosterToTeam(selectedTeam) : undefined
            }
          />
        </div>
      ) : (
        <div className={styles.noTeamsText}>
          <Typography size="large" variant="ui">
            {NO_TEAMS_TEXT}
          </Typography>
        </div>
      )}
    </ul>
  );
};

const FilteredTeamList = ({
  teamList,
  onTeamSelect,
  selectedTeam,
}: {
  teamList: TeamType[];
  onTeamSelect: (team: TeamType) => void;
  selectedTeam: TeamType | undefined;
}) => {
  return (
    <>
      {teamList.map((team) => {
        if (team.id.toString() === selectedTeam?.id.toString()) {
          return null;
        }
        return (
          <div data-testid={getTeamId(team.id, team.programId!)} key={team.id}>
            <Team team={team} onClick={() => onTeamSelect(team)} />
          </div>
        );
      })}
    </>
  );
};

function transformRosterToTeam(roster: Roster): TeamType {
  return {
    ...roster.teamInfo,
    id: roster.teamInfo.teamIdOg.toString(),
    createdOn: roster.createdOn,
    siteName: roster.siteName,
    teamRepresentative: {
      ...roster.teamInfo.teamRepresentative,
      phoneNumber: roster.teamInfo.teamRepresentative.phone,
    },
  };
}
