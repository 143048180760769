import { RegistrationDecodedParams } from '@la/types';

const clearLocalStorage = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key));
};

export const getDecodedData = (
  selectRegistrationData: string | null,
  initialRegistrationId: string | null
) => {
  if (initialRegistrationId) {
    clearLocalStorage(['lip.decodedParams', 'lip.invite-token']);
    localStorage.setItem('lip.registrationId', initialRegistrationId);
    return { decodedParams: null, encodedToken: null, initialRegistrationId };
  }

  if (selectRegistrationData) {
    const decodedParams = JSON.parse(
      window.atob(selectRegistrationData)
    ) as RegistrationDecodedParams;
    clearLocalStorage(['lip.invite-token', 'lip.registrationId']);
    localStorage.setItem('lip.decodedParams', JSON.stringify(decodedParams));
    return { decodedParams, encodedToken: null };
  }

  const encodedToken = window.location.search.substring(1);
  if (encodedToken) {
    clearLocalStorage(['lip.decodedParams', 'lip.registrationId']);
    localStorage.setItem('lip.invite-token', encodedToken);
    return { encodedToken, decodedParams: null };
  }

  const decodedParamsFromStorage = localStorage.getItem('lip.decodedParams');
  if (decodedParamsFromStorage) {
    const decodedParams = JSON.parse(
      decodedParamsFromStorage
    ) as RegistrationDecodedParams;
    clearLocalStorage(['lip.invite-token', 'lip.registrationId']);
    return { decodedParams, encodedToken: null };
  }

  const encodedTokenFromStorage = localStorage.getItem('lip.invite-token');
  if (encodedTokenFromStorage) {
    clearLocalStorage(['lip.decodedParams', 'lip.registrationId']);
    return { decodedParams: null, encodedToken: encodedTokenFromStorage };
  }

  throw new Error('No context provided for member registration');
};
