import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { CloseCircleIcon, MultiSelect, PencilSquareIcon, Typography, Button, WarningIcon, } from '@la/ds-ui-components';
import { useGetTeamRepLabelQuery, useGetUserRegistrationsQuery, } from '@la/services';
import { RegistrationStatus, } from '@la/types';
import { getSiteIdFromURL } from '@la/utilities';
import { useMediaQuery } from '../../lib/hooks';
import { breakpointQueries } from '../../lib/media-queries/breakpoints';
import { UpdateTeamDialog, } from '../../tournaments/registration/Wizard/components/DivisionCard/DivisionCardTeamSelectionSection/UpdateTeamDialog';
import { getNumberOfSpotsLeft } from '../../tournaments/registration/Wizard/components/DivisionCard/utils/capacity';
// import { useTeamsWithFields } from 'domains/Tournaments/Registration/hooks/useTeamsWithFields';
import { AT_CAPACITY_DISABLED_REASON, disableTeamForAtCapacity, enableTeamForAtCapacity, isDivisionAtCapacity, } from './utils/capacity';
import { hasIncompleteRequiredFields } from './utils/validation';
import * as S from './TeamSelectionSection.styles';
const createNewTeamOption = {
    name: '+ Create team',
    value: 'create-team',
    hasCheckbox: false,
};
const mapTeamsToMultiSelectOptions = (teams, divisionAtCapacity, registeredTeams, selectedTeams) => {
    return teams.map((team) => {
        var _a;
        const isRegistered = registeredTeams.includes(team.id);
        const isSelected = selectedTeams.includes(team.id);
        const disabled = divisionAtCapacity || isRegistered || isSelected;
        let disabledReason;
        if (divisionAtCapacity) {
            disabledReason = AT_CAPACITY_DISABLED_REASON;
        }
        return {
            name: team.name,
            value: team.id,
            subtitle: (_a = team.teamRepresentative) === null || _a === void 0 ? void 0 : _a.name,
            badgeText: isRegistered ? 'Registered' : undefined,
            disabled,
            disabledReason,
            checkboxState: isSelected ? true : undefined,
        };
    });
};
export const TeamSelectionSection = ({ teams, openCreateTeamDialog, onSelectTeam, onAddTeamSelect, onUpdateTeam, onDeleteTeam, onDiscardTeamSelect, onClear, division, cardState, teamsList, maxDivisionAgeGroup, tournamentId, setHasDeleteTeamDivisionError, selectError, setSelectError, selectRef, }) => {
    const siteId = getSiteIdFromURL();
    const { teamSelections } = cardState;
    const numberOfSpotsLeft = getNumberOfSpotsLeft(division);
    const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);
    const { data: teamRepLabel } = useGetTeamRepLabelQuery({
        siteId: siteId.toString(),
    });
    const registeredTeams = useMemo(() => {
        var _a;
        const teams = (_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.filter((registration) => registration.registrationStatus === RegistrationStatus.Registered).map((registration) => registration.teamId);
        return teams !== null && teams !== void 0 ? teams : [];
    }, [division]);
    const [selectedTeams, setSelectedTeams] = useState(() => {
        const selectedIds = teamSelections.map((selection) => selection.teamId);
        return mapTeamsToMultiSelectOptions(teamsList, isDivisionAtCapacity(division, teamSelections), registeredTeams, selectedIds !== null && selectedIds !== void 0 ? selectedIds : []);
    });
    const hasMoreOptionsThanSpotsAvailable = numberOfSpotsLeft !== undefined &&
        selectedTeams.filter((selectedTeam) => !selectedTeam.disabled ||
            selectedTeam.disabledReason === AT_CAPACITY_DISABLED_REASON).length > numberOfSpotsLeft;
    const multiSelectHelperText = hasMoreOptionsThanSpotsAvailable ? (_jsxs(_Fragment, { children: ["This division only has capacity for\u00A0", _jsx("strong", { children: numberOfSpotsLeft }), "\u00A0team", numberOfSpotsLeft !== 1 ? 's' : '', "."] })) : undefined;
    useEffect(() => {
        if (!selectError) {
            const selectedIds = teamSelections.map((selection) => selection.teamId);
            setSelectedTeams(mapTeamsToMultiSelectOptions(teamsList, isDivisionAtCapacity(division, teamSelections), registeredTeams, selectedIds !== null && selectedIds !== void 0 ? selectedIds : []));
        }
    }, [division, registeredTeams, teamSelections, teamsList, selectError]);
    const divisionId = division.id;
    const { data } = useGetUserRegistrationsQuery({
        siteId: siteId.toString(),
        programId: division.id,
    });
    const toggleAtCapacityOptions = (updatedTeamOptions) => {
        const selectedTeamsCount = updatedTeamOptions.filter((team) => team.checkboxState).length;
        if (numberOfSpotsLeft !== undefined) {
            if (selectedTeamsCount >= numberOfSpotsLeft) {
                return updatedTeamOptions.map((teamOption) => disableTeamForAtCapacity(teamOption, teamSelections));
            }
            return updatedTeamOptions.map(enableTeamForAtCapacity);
        }
        return updatedTeamOptions;
    };
    const handleSelectTeam = (teamId) => {
        if (teamId === createNewTeamOption.value) {
            openCreateTeamDialog(teamSelections.length);
            return;
        }
        setSelectedTeams((teams) => {
            return toggleAtCapacityOptions(teams.map((team) => {
                if (team.value === teamId) {
                    const isSelected = team.isSelected;
                    return Object.assign(Object.assign({}, team), { isSelected: !isSelected, shouldRender: !isSelected, checkboxState: !isSelected });
                }
                return team;
            }));
        });
    };
    const handleSelectAll = () => {
        if (selectedTeams.every((team) => team.disabled)) {
            return;
        }
        setSelectedTeams((teams) => {
            const hasSelected = teams.some((team) => team.isSelected);
            return teams.map((team) => (Object.assign(Object.assign({}, team), { isSelected: hasSelected || team.disabled ? false : true, shouldRender: hasSelected || team.disabled ? false : true, checkboxState: team.disabled ? team.checkboxState : undefined })));
        });
    };
    const handleTagDismiss = (teamId) => {
        if (!teamId) {
            return;
        }
        setSelectedTeams((teams) => {
            return toggleAtCapacityOptions(teams.map((team) => {
                if (team.value === teamId) {
                    const isSelected = team.isSelected;
                    return Object.assign(Object.assign({}, team), { isSelected: !isSelected, shouldRender: !isSelected, checkboxState: !isSelected });
                }
                return team;
            }));
        });
    };
    const handleAddTeams = () => {
        setSelectError();
        const selectedIds = selectedTeams
            .filter((team) => team.isSelected)
            .map((team) => team.value);
        selectedIds.forEach((teamId, index) => {
            onAddTeamSelect();
            onSelectTeam(index + teamSelections.length, teamId);
        });
        setSelectedTeams((teams) => {
            return teams.map((team) => {
                if (selectedIds.includes(team.value)) {
                    return Object.assign(Object.assign({}, team), { isSelected: false, shouldRender: false, disabled: true, checkboxState: true });
                }
                return team;
            });
        });
    };
    const handleDiscardTeam = (selectIndex) => {
        const { teamId } = teamSelections[selectIndex];
        setSelectedTeams((teams) => {
            return teams.map((team) => {
                if (team.value !== teamId) {
                    return team;
                }
                return Object.assign(Object.assign({}, team), { disabled: false, isSelected: false, checkboxState: false });
            });
        });
        onDiscardTeamSelect(selectIndex);
        if (teamSelections.length === 1) {
            onClear();
        }
    };
    const isAddTeamsDisabled = selectedTeams.every((team) => !team.isSelected);
    const hasSelected = selectedTeams.some((team) => team.isSelected);
    return (_jsxs(S.TeamSelectionContainer, { children: [_jsx(Typography, Object.assign({ variant: "headline", size: "xs" }, { children: "Add your teams" })), _jsxs(S.SelectSection, { children: [_jsx(S.SelectContainer, { children: _jsx(MultiSelect, { disabledSelectAll: hasMoreOptionsThanSpotsAvailable, helperText: multiSelectHelperText, options: selectedTeams, placeholder: "Select your teams", id: "team-selection-multiselect", selectAllText: "Select all", displayClearButton: false, primarySubtitle: isTabletPortaitUp
                                ? 'Team name'
                                : `Team name / ${teamRepLabel} name`, secondarySubtitle: isTabletPortaitUp ? `${teamRepLabel} name` : undefined, bottomAction: Object.assign(Object.assign({}, createNewTeamOption), { disabled: numberOfSpotsLeft !== undefined &&
                                    selectedTeams.filter((selectedTeam) => selectedTeam.checkboxState).length >= numberOfSpotsLeft }), onSelectValue: handleSelectTeam, onSelectAll: handleSelectAll, onSelectClear: () => { }, onTagDismiss: handleTagDismiss, hasError: selectError, errorMessage: hasSelected
                                ? 'Please select "Add teams" before saving division'
                                : 'Please add teams before saving division', ref: selectRef }) }), _jsx(S.AddTeamsButtonContainer, { children: _jsx(Button, Object.assign({ variant: "outline", size: "large", disabled: isAddTeamsDisabled, onClick: handleAddTeams, width: "100%" }, { children: "Add teams" })) })] }), teamSelections.length ? (_jsx(S.AddedTeamsContainer, { children: teamSelections.map((selection, i) => {
                    var _a;
                    const team = teams.find((team) => team.id === selection.teamId);
                    const hasDivisionsWithTeamSelected = (_a = data === null || data === void 0 ? void 0 : data.userRegistrations.some((registration) => registration.teamId === (team === null || team === void 0 ? void 0 : team.id))) !== null && _a !== void 0 ? _a : false;
                    if (!team) {
                        return null;
                    }
                    return (_jsx(AddedTeam, { team: team, isLast: i === teamSelections.length - 1, selectIndex: i, divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, tournamentId: tournamentId, onUpdateTeam: onUpdateTeam, onDeleteTeam: onDeleteTeam, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, onDiscard: handleDiscardTeam, customFields: division.customTeamFields }, team.id));
                }) })) : null] }));
};
const AddedTeam = ({ team, isLast, selectIndex, divisionId, maxDivisionAgeGroup, hasDivisionsWithTeamSelected, tournamentId, onUpdateTeam, onDeleteTeam, setHasDeleteTeamDivisionError, onDiscard, customFields, }) => {
    var _a;
    const showIncompleteFieldsWarning = hasIncompleteRequiredFields(customFields, team);
    return (_jsxs(S.AddedTeam, Object.assign({ "$isLast": isLast }, { children: [_jsxs(S.AddedTeamNameContainer, { children: [_jsx(S.AddedTeamName, Object.assign({ variant: "ui", size: "large", weight: "bold" }, { children: team.name })), _jsx(Typography, Object.assign({ variant: "ui", size: "medium", italic: true }, { children: (_a = team.teamRepresentative) === null || _a === void 0 ? void 0 : _a.name })), showIncompleteFieldsWarning ? (_jsx(WarningIcon, { variant: "filled", size: "large", fill: "#F98A28" })) : null] }), _jsxs(S.ActionIconsContainer, { children: [_jsx(UpdateTeamDialog, { divisionId: divisionId, maxDivisionAgeGroup: maxDivisionAgeGroup, hasDivisionsWithTeamSelected: hasDivisionsWithTeamSelected, onDeleteTeam: onDeleteTeam, onUpdateTeam: onUpdateTeam, team: team, tournamentId: tournamentId, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, trigger: _jsx(S.DiscardTeamButtonContainer, Object.assign({ "$disabled": false }, { children: _jsx(PencilSquareIcon, { size: "large", fill: "var(--secondary-500)" }) })), customFields: customFields }), _jsx(S.DiscardTeamButtonContainer, Object.assign({ "$disabled": false, onClick: () => {
                            onDiscard(selectIndex);
                        } }, { children: _jsx(CloseCircleIcon, { variant: "filled", size: "large", fill: 'var(--secondary-500)' }) }))] })] })));
};
