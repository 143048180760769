// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taRIqQzkgJSiUIVxI32a{min-height:72px;padding:8px 12px;border:1px solid var(--blue-grey-400);border-radius:4px;flex:100%;max-width:calc(100% - 8px);width:100%;background-color:#fff;cursor:pointer}@media(min-width: 900px){.taRIqQzkgJSiUIVxI32a{flex:50%;max-width:calc(50% - 8px)}}.s6BxHjHyDwTgxIJLbXe1{cursor:not-allowed}.s6BxHjHyDwTgxIJLbXe1 p,.s6BxHjHyDwTgxIJLbXe1 label{color:var(--blue-grey-200)}.y6oYOo2W0a7KdfWCt49Q{display:flex;justify-content:space-between}.O3tZpYHEFPJYfI4xdYpV{display:flex;align-items:center;gap:8px}._1qyXOIk9iFHDUfQpq4TQ{height:20px;width:20px;accent-color:var(--blue-grey-600)}.rShYtI2JmiOaq4Kbik29{margin-left:30px}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/SelectRegistrationType/RegistrationTypeItem/RegistrationTypeItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,qCAAA,CACA,iBAAA,CACA,SAAA,CACA,0BAAA,CACA,UAAA,CACA,qBAAA,CACA,cAAA,CAEA,yBAXF,sBAYI,QAAA,CACA,yBAAA,CAAA,CAIJ,sBACE,kBAAA,CACA,oDAEE,0BAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,uBACE,WAAA,CACA,UAAA,CACA,iCAAA,CAGF,sBACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `taRIqQzkgJSiUIVxI32a`,
	"disabledContainer": `s6BxHjHyDwTgxIJLbXe1`,
	"header": `y6oYOo2W0a7KdfWCt49Q`,
	"radioContainer": `O3tZpYHEFPJYfI4xdYpV`,
	"radioInput": `_1qyXOIk9iFHDUfQpq4TQ`,
	"subtitle": `rShYtI2JmiOaq4Kbik29`
};
export default ___CSS_LOADER_EXPORT___;
