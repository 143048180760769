import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BedIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
} from '@la/ds-ui-components';
import { useMediaQuery } from '@la/shared-components/src/lib/hooks';
import { breakpointQueries } from '@la/shared-components/src/lib/media-queries/breakpoints';
import {
  getLAHostnameParts,
  isPlayerRegistration,
  isStaffRegistration,
} from '@la/utilities';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import {
  injectRegistrationAnalyticsData,
  parseHTML,
} from 'lib/utils/analytics';
import { formatFormFieldValue } from 'lib/utils/formField';
import { useGetProgramQuery } from 'redux/services/activity';
import { useLazyGetRegistrationIdsQuery } from 'redux/services/registrationApi';
import { useGetSiteTrackingSettingsQuery } from 'redux/services/siteInfo';
import * as S from './RegistrationSuccessCard.styles';

export const ACCOMMODATIONS_BUTTON_LABEL = 'Manage accommodations';

export const RegistrationSuccessCard = () => {
  const { subdomain } = getLAHostnameParts();
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const navigate = useNavigate();
  const {
    ctx,
    loggedInUserId,
    nonFileUploadFormFields,
    selectedPlayer,
    childPlayers,
    masterProgramName,
    hotelLink,
    registrationId,
  } = useRegistration();
  const [showDetails, setShowDetails] = useState(false);

  const [getRegistrationIds] = useLazyGetRegistrationIdsQuery();

  const { data: programData } = useGetProgramQuery(
    {
      siteId: ctx?.siteId.toString() ?? '',
      programId: ctx?.programId.toString() ?? '',
    },
    { skip: !ctx }
  );

  const { data: siteTrackingSettings } = useGetSiteTrackingSettingsQuery(
    { siteId: ctx?.siteId ?? 0 },
    { skip: !ctx }
  );

  useEffect(() => {
    if (
      ctx &&
      !!(isPlayerRegistration(ctx.type) ? selectedPlayer : loggedInUserId) &&
      programData &&
      registrationId
    ) {
      getRegistrationIds({
        siteDomain: subdomain,
        registrationIds: [registrationId],
      })
        .unwrap()
        .then((registrationIds) => {
          injectRegistrationAnalyticsData({
            programFullName: programData.name,
            programId: programData.id,
            programType: programData.type,
            programSport: programData.details?.sport,
            registrationUserId: isPlayerRegistration(ctx.type)
              ? selectedPlayer
              : loggedInUserId.toString(),
            registrationId:
              registrationIds[registrationId].registrationIdOg.toString(),
          });
        });

      if (siteTrackingSettings?.conversionTrackingRegistration) {
        document.body.append(
          parseHTML(siteTrackingSettings.conversionTrackingRegistration)
        );
      }
    }
  }, [
    ctx,
    getRegistrationIds,
    loggedInUserId,
    programData,
    registrationId,
    selectedPlayer,
    siteTrackingSettings,
    subdomain,
  ]);

  const handleOnDetailsClick = () => {
    setShowDetails((show) => !show);
  };

  const handleRosterClick = () => {
    if (!ctx?.teamId) {
      return;
    }

    navigate(`/app/tournaments/${ctx?.programId}/teams/${ctx.teamId}/roster`);
  };

  const handleDashboardClick = () => {
    navigate(`/dashboard`);
  };

  if (!ctx) {
    return null;
  }

  const localSelectedPlayer = localStorage.getItem('selectedPlayer');
  const player = childPlayers.find((player) => {
    const id = player.id.toString();
    return id === selectedPlayer || id === localSelectedPlayer;
  });

  return (
    <S.SuccessCardWrapper $role={ctx.type}>
      <S.SuccessCardContainer $role={ctx.type}>
        <Typography variant="headline" size="small">
          You're all set!
        </Typography>
        <Typography variant="ui" size="large">
          You will receive a confirmation email in your inbox shortly. If you
          have any questions, please contact the tournament administrator.
        </Typography>
      </S.SuccessCardContainer>
      {isPlayerRegistration(ctx.type) ? (
        <S.CardsArea>
          <Card width="100%">
            <CardHeader>{masterProgramName}</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  <b>{`${player?.firstName} ${player?.lastName}`}</b>{' '}
                  registration
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                <Button
                  variant="outline"
                  onClick={handleOnDetailsClick}
                  rightIcon={
                    <S.AccordionCaretContainer $isOpened={showDetails}>
                      <S.AccordionCaret />
                    </S.AccordionCaretContainer>
                  }
                >
                  {!showDetails ? 'View details' : 'Hide details'}
                </Button>
              </S.ButtonContainer>
            </CardBody>
            {showDetails ? (
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader>Registration field</TableHeader>
                    <TableHeader>Field response</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {Object.values(nonFileUploadFormFields).map((field) => (
                    <TableRow key={field.propertyDefinitionId}>
                      <TableCell>{field.name}</TableCell>
                      <TableCell>{formatFormFieldValue(field)}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Card>
          <Card width="100%" height="auto">
            <CardHeader>Member dashboard</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  Now that you are registered you can view your schedule and any
                  other team info in your member dashboard
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                <Button variant="primary" onClick={handleDashboardClick}>
                  Visit dashboard
                </Button>
              </S.ButtonContainer>
            </CardBody>
          </Card>
        </S.CardsArea>
      ) : null}
      {isStaffRegistration(ctx.type) ? (
        <S.StaffCardContainer>
          <Card height="auto">
            <CardHeader>What's next?</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  Now that you are registered you can begin managing your roster
                  and complete other team rep tasks
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                {ctx?.teamId ? (
                  <Button variant="primary" onClick={handleRosterClick}>
                    Manage roster
                  </Button>
                ) : null}
                <Button variant="outline" onClick={handleDashboardClick}>
                  View dashboard
                </Button>
                {hotelLink ? (
                  <S.HotelLink>
                    <Link
                      hasPadding
                      href={hotelLink}
                      isBold
                      target="_blank"
                      variant="outline"
                      width={isTabletPortaitUp ? 'auto' : '100%'}
                    >
                      <BedIcon fill="var(--secondary-600)" variant="bold" />
                      {ACCOMMODATIONS_BUTTON_LABEL}
                    </Link>
                  </S.HotelLink>
                ) : null}
              </S.ButtonContainer>
            </CardBody>
          </Card>
        </S.StaffCardContainer>
      ) : null}
    </S.SuccessCardWrapper>
  );
};
