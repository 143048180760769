import { useFlags } from 'launchdarkly-react-client-sdk';
import { Card, CardBody, CardHeader } from '@la/ds-ui-components';
import { FileUploadFormField } from '@la/types';
import {
  formatFormFieldsForWorkflow,
  getLAHostnameParts,
  isPlayerRegistration,
  isStaffRegistration,
} from '@la/utilities';
import {
  CustomFieldsForm,
  CustomFieldsFormFields,
} from 'components/RegistrationInfoCard/CustomFieldsForm/CustomFieldsForm';
import { updateWorkflowRegistration } from 'lib/apis/updateWorkflowRegistration';
import {
  getProgramRole,
  useRegistration,
} from 'lib/context/RegistrationContext/RegistrationContext';
import styles from './RegistrationFileUpload.module.scss';

export const FILE_UPLOAD_FORM_FIELDS_NAME = 'fileUploadFormFilelds';

export const RegistrationFileUpload = () => {
  const { tournamentTeamRegistrationWizardVersion } = useFlags();

  const {
    currentStep,
    dispatch,
    fileUploadFormFields,
    formSteps,
    onNextClick,
    uploadFile,
    ctx,
    name,
    selectedPlayer,
    loggedInUserId,
    nonFileUploadFormFields,
  } = useRegistration();
  const fields = Object.values(fileUploadFormFields);
  const { subdomain } = getLAHostnameParts();

  if (!fields.length) {
    return null;
  }

  const handleFileUploadSubmit = async ({
    [formSteps[currentStep]]: customFields,
  }: CustomFieldsFormFields): Promise<void> => {
    const fields = customFields.filter(
      (field): field is FileUploadFormField => field.type === 'FILE_UPLOAD'
    );

    const fileFields: {
      formFieldId: number;
      type: 'FILE_UPLOAD';
      values: [name: string, uuid: string];
    }[] = [];

    const promises = fields.map((field) => {
      const { propertyDefinitionId, value, type } = field;

      if (!value || !value.file) {
        return null;
      }

      return uploadFile({
        file: value,
        propertyDefinitionId: propertyDefinitionId.toString(),
        userId: loggedInUserId.toString(),
      }).then(({ uuid }) => {
        fileFields.push({
          values: [value.name, uuid],
          formFieldId: propertyDefinitionId,
          type,
        });

        dispatch({
          type: 'SET_FILE_UPLOAD_FORM_FIELD',
          payload: {
            id: field.propertyDefinitionId,
            field: {
              ...field,
              value: { ...value, uuid },
            },
          },
        });
      });
    });

    await Promise.all(promises);

    if (!ctx) {
      return;
    }

    const registrationFormFields = formatFormFieldsForWorkflow(
      Object.values(nonFileUploadFormFields)
    );
    updateWorkflowRegistration(
      {
        siteId: ctx.siteId,
        name,
        programRole: getProgramRole(ctx.type),
        programStaffId:
          isStaffRegistration(ctx.type) && ctx.roleId
            ? parseInt(ctx.roleId.toString())
            : undefined,
        registeredUserId: isPlayerRegistration(ctx.type)
          ? parseInt(selectedPlayer)
          : loggedInUserId,
        registeringUserId: loggedInUserId,
        registrationType: ctx.type,
        programId: parseInt(ctx.programId.toString()),
        teamIdOg: ctx.teamId ? parseInt(ctx.teamId.toString()) : 0,
        formFields: [fileFields, registrationFormFields].flat(),
        workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
      },
      subdomain
    ).then(onNextClick);
  };

  return (
    <div className={styles.registrationFileUpload}>
      <Card width="100%">
        <CardHeader>Documents</CardHeader>
        <CardBody>
          <CustomFieldsForm
            columns={1}
            gap={24}
            formFields={fields}
            id={formSteps[currentStep]}
            onSubmit={handleFileUploadSubmit}
          />
        </CardBody>
      </Card>
    </div>
  );
};
