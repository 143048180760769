import { CalendarBlank, MapPin } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import { formatDateRange } from 'lib/utils/dateUtils';
import styles from './OrderSummaryCard.module.scss';
import { CartItemBodyProps } from './OrderSummaryCardBody';
import ProgramRegistrationsTable from './ProgramRegistrationsTable';

export default function OrderSummaryCardDetails({
  cartItemUuid,
  location,
  programDateInfo,
  subprograms = [],
  subtype,
}: Readonly<
  Pick<
    CartItemBodyProps,
    'cartItemUuid' | 'location' | 'programDateInfo' | 'subprograms' | 'subtype'
  >
>) {
  return (
    <div
      className={styles.orderSummaryCardDetails}
      data-testid="order-summary-card-details"
    >
      <div className={styles.locationAndDate}>
        <LocationInfo location={location} />
        <ProgramDateInfo programDateInfo={programDateInfo} />
      </div>
      <ProgramRegistrationsTable
        cartItemUuid={cartItemUuid}
        subprograms={subprograms}
        subtype={subtype}
      />
    </div>
  );
}

function LocationInfo({ location }: { location?: string }) {
  return location ? (
    <span>
      <MapPin size={16} weight="fill" />
      {location}
    </span>
  ) : null;
}

function ProgramDateInfo({
  programDateInfo,
}: Readonly<{
  programDateInfo: string | null;
}>) {
  return programDateInfo ? (
    <span>
      <CalendarBlank size={16} weight="fill" />
      {programDateInfo}
    </span>
  ) : null;
}

export function formatDateForCard(startDate?: string, endDate?: string) {
  if (startDate && endDate) {
    return formatDateRange(
      DateTime.fromISO(startDate, { zone: 'utc' }),
      DateTime.fromISO(endDate, { zone: 'utc' })
    );
  } else if (startDate) {
    return formatDateRange(DateTime.fromISO(startDate, { zone: 'utc' }));
  } else {
    return null;
  }
}
