import { CheckoutCardHeader } from '../CheckoutCard/CheckoutCard';
import ConfirmationBillingSummary from '../ConfirmationBillingSummary/ConfirmationBillingSummary';
import * as S from './ConfirmationRollup.styles';

/* ConfirmationRollup */
export default function ConfirmationRollup() {
  return (
    <S.ConfirmationRollup>
      <CheckoutCardHeader name="Your order" />
      <ConfirmationBillingSummary showHotelLinks />
    </S.ConfirmationRollup>
  );
}
/* */
