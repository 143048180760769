import { Card, CardBody, CardHeader, Typography } from '@la/ds-ui-components';
import { convertToKebabCase } from '@la/utilities';
import { isFree } from '../utils/cost';
import styles from './FeesCard.module.scss';
import { formatCostRange } from './utils/cost';

export const NO_FEES_TEXT = 'There are currently no fees for this program.';
export const FREE_TEXT = 'Free';

export type Fee = {
  label: string;
  min: string;
  max: string;
};

export type FeesCardProps = {
  fees: Fee[];
};

/* FeesCard */
export function FeesCard({ fees }: FeesCardProps) {
  return (
    <div className={styles.feesCard}>
      <Card>
        <CardHeader>Fees</CardHeader>
        <CardBody>
          {fees.length === 0 ? (
            <Typography className={styles.noFees} size="large" variant="ui">
              {NO_FEES_TEXT}
            </Typography>
          ) : null}
          {fees.map((fee) => (
            <div key={convertToKebabCase(fee.label)} className={styles.fee}>
              <Typography size="large" variant="ui" weight="bold">
                {fee.label}
              </Typography>
              <Typography size="large" variant="ui">
                {isFree(fee.max)
                  ? FREE_TEXT
                  : formatCostRange(fee.min, fee.max)}
              </Typography>
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
}
/* */
