import { useState, useEffect, useMemo } from 'react';
import {
  CloseCircleIcon,
  MultiSelect,
  MultiSelectOptionProps,
  PencilSquareIcon,
  Typography,
  Button,
  WarningIcon,
} from '@la/ds-ui-components';
import { getSiteId } from '@la/services';
import { useMediaQuery, breakpointQueries } from '@la/shared-components';
import { FormField, RegistrationStatus } from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import { useGetTeamRepLabelQuery } from 'redux/services/staffRoleApi';
import { useGetUserRegistrationsQuery } from 'redux/services/tournamentApi';
import { Team } from 'redux/services/types/team';
import { useAppSelector } from 'redux/store';
// import { useTeamsWithFields } from 'domains/Tournaments/Registration/hooks/useTeamsWithFields';
import {
  DivisionNotSaved,
  DivisionSavedAndEditing,
} from '../../../Wizard.types';
import { AvailableDivisionCardProps } from '../DivisionCard';
import {
  UpdateTeamDialog,
  UpdateTeamDialogProps,
} from '../DivisionCardTeamSelectionSection/UpdateTeamDialog';
import { getNumberOfSpotsLeft } from '../utils/capacity';
import {
  AT_CAPACITY_DISABLED_REASON,
  disableTeamForAtCapacity,
  enableTeamForAtCapacity,
  isDivisionAtCapacity,
} from './utils/capacity';
import { hasIncompleteRequiredFields } from './utils/validation';
import * as S from './TeamSelectionSection.styles';

type TeamSelectionSectionProps = {
  teams: Team[];
  openCreateTeamDialog: (selectIndex: number) => void;
  onSelectTeam: (selectIndex: number, value: string) => void;
  cardState: DivisionNotSaved | DivisionSavedAndEditing;
  openDiscardConfirmationDialog: () => void;
  teamsList: Team[];
  tournamentId: string;
  setHasDeleteTeamDivisionError: (error: boolean) => void;
  onAddTeamSelect: () => void;
  selectError: boolean;
  setSelectError: () => void;
  selectRef: React.RefObject<HTMLButtonElement>;
} & Pick<
  AvailableDivisionCardProps,
  | 'division'
  | 'divisionCardsState'
  | 'maxDivisionAgeGroup'
  | 'onClear'
  | 'onDeleteTeam'
  | 'onDiscardTeamSelect'
  | 'onSelectTeam'
  | 'tournamentId'
> &
  Pick<UpdateTeamDialogProps, 'onUpdateTeam'>;

const createNewTeamOption = {
  name: '+ Create team',
  value: 'create-team',
  hasCheckbox: false,
};

const mapTeamsToMultiSelectOptions = (
  teams: Team[],
  divisionAtCapacity: boolean,
  registeredTeams: (string | undefined | null)[],
  selectedTeams: string[]
): MultiSelectOptionProps[] => {
  return teams.map((team) => {
    const isRegistered = registeredTeams.includes(team.id);
    const isSelected = selectedTeams.includes(team.id);

    const disabled = divisionAtCapacity || isRegistered || isSelected;
    let disabledReason;
    if (divisionAtCapacity) {
      disabledReason = AT_CAPACITY_DISABLED_REASON;
    }

    return {
      name: team.name,
      value: team.id,
      subtitle: team.teamRepresentative?.name,
      badgeText: isRegistered ? 'Registered' : undefined,
      disabled,
      disabledReason,
      checkboxState: isSelected ? true : undefined,
    };
  });
};

export const TeamSelectionSection = ({
  teams,
  openCreateTeamDialog,
  onSelectTeam,
  onAddTeamSelect,
  onUpdateTeam,
  onDeleteTeam,
  onDiscardTeamSelect,
  onClear,
  division,
  cardState,
  teamsList,
  maxDivisionAgeGroup,
  tournamentId,
  setHasDeleteTeamDivisionError,
  selectError,
  setSelectError,
  selectRef,
}: TeamSelectionSectionProps) => {
  const { teamSelections } = cardState;
  const numberOfSpotsLeft = getNumberOfSpotsLeft(division);

  const { subdomain } = getLAHostnameParts();
  const siteId = useAppSelector(getSiteId);
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const { data: teamRepLabel } = useGetTeamRepLabelQuery({
    siteId: siteId ?? '',
  });

  const registeredTeams = useMemo(() => {
    const teams = division.registrationsForUser
      ?.filter(
        (registration) =>
          registration.registrationStatus === RegistrationStatus.Registered
      )
      .map((registration) => registration.teamId);
    return teams ?? [];
  }, [division]);

  const [selectedTeams, setSelectedTeams] = useState<MultiSelectOptionProps[]>(
    () => {
      const selectedIds = teamSelections.map((selection) => selection.teamId);
      return mapTeamsToMultiSelectOptions(
        teamsList,
        isDivisionAtCapacity(division, teamSelections),
        registeredTeams,
        selectedIds ?? []
      );
    }
  );

  const hasMoreOptionsThanSpotsAvailable =
    numberOfSpotsLeft !== undefined &&
    selectedTeams.filter(
      (selectedTeam) =>
        !selectedTeam.disabled ||
        selectedTeam.disabledReason === AT_CAPACITY_DISABLED_REASON
    ).length > numberOfSpotsLeft;
  const multiSelectHelperText = hasMoreOptionsThanSpotsAvailable ? (
    <>
      This division only has capacity for&nbsp;
      <strong>{numberOfSpotsLeft}</strong>&nbsp;team
      {numberOfSpotsLeft !== 1 ? 's' : ''}.
    </>
  ) : undefined;

  useEffect(() => {
    if (!selectError) {
      const selectedIds = teamSelections.map((selection) => selection.teamId);
      setSelectedTeams(
        mapTeamsToMultiSelectOptions(
          teamsList,
          isDivisionAtCapacity(division, teamSelections),
          registeredTeams,
          selectedIds ?? []
        )
      );
    }
  }, [division, registeredTeams, teamSelections, teamsList, selectError]);

  const divisionId = division.id;

  const { data } = useGetUserRegistrationsQuery({
    siteDomain: subdomain,
    programId: division.id,
  });

  const toggleAtCapacityOptions = (
    updatedTeamOptions: MultiSelectOptionProps[]
  ) => {
    const selectedTeamsCount = updatedTeamOptions.filter(
      (team) => team.checkboxState
    ).length;

    if (numberOfSpotsLeft !== undefined) {
      if (selectedTeamsCount >= numberOfSpotsLeft) {
        return updatedTeamOptions.map((teamOption) =>
          disableTeamForAtCapacity(teamOption, teamSelections)
        );
      }
      return updatedTeamOptions.map(enableTeamForAtCapacity);
    }

    return updatedTeamOptions;
  };

  const handleSelectTeam = (teamId: string) => {
    if (teamId === createNewTeamOption.value) {
      openCreateTeamDialog(teamSelections.length);
      return;
    }
    setSelectedTeams((teams) => {
      return toggleAtCapacityOptions(
        teams.map((team) => {
          if (team.value === teamId) {
            const isSelected = team.isSelected;
            return {
              ...team,
              isSelected: !isSelected,
              shouldRender: !isSelected,
              checkboxState: !isSelected,
            };
          }
          return team;
        })
      );
    });
  };

  const handleSelectAll = () => {
    if (selectedTeams.every((team) => team.disabled)) {
      return;
    }
    setSelectedTeams((teams) => {
      const hasSelected = teams.some((team) => team.isSelected);
      return teams.map((team) => ({
        ...team,
        isSelected: hasSelected || team.disabled ? false : true,
        shouldRender: hasSelected || team.disabled ? false : true,
        checkboxState: team.disabled ? team.checkboxState : undefined,
      }));
    });
  };

  const handleTagDismiss = (teamId?: string) => {
    if (!teamId) {
      return;
    }

    setSelectedTeams((teams) => {
      return toggleAtCapacityOptions(
        teams.map((team) => {
          if (team.value === teamId) {
            const isSelected = team.isSelected;
            return {
              ...team,
              isSelected: !isSelected,
              shouldRender: !isSelected,
              checkboxState: !isSelected,
            };
          }
          return team;
        })
      );
    });
  };

  const handleAddTeams = () => {
    setSelectError();
    const selectedIds = selectedTeams
      .filter((team) => team.isSelected)
      .map((team) => team.value);

    selectedIds.forEach((teamId, index) => {
      onAddTeamSelect();
      onSelectTeam(index + teamSelections.length, teamId);
    });

    setSelectedTeams((teams) => {
      return teams.map((team) => {
        if (selectedIds.includes(team.value)) {
          return {
            ...team,
            isSelected: false,
            shouldRender: false,
            disabled: true,
            checkboxState: true,
          };
        }
        return team;
      });
    });
  };

  const handleDiscardTeam = (selectIndex: number) => {
    const { teamId } = teamSelections[selectIndex];

    setSelectedTeams((teams) => {
      return teams.map((team) => {
        if (team.value !== teamId) {
          return team;
        }
        return {
          ...team,
          disabled: false,
          isSelected: false,
          checkboxState: false,
        };
      });
    });

    onDiscardTeamSelect(selectIndex);

    if (teamSelections.length === 1) {
      onClear();
    }
  };

  const isAddTeamsDisabled = selectedTeams.every((team) => !team.isSelected);
  const hasSelected = selectedTeams.some((team) => team.isSelected);
  return (
    <S.TeamSelectionContainer>
      <Typography variant="headline" size="xs">
        Add your teams
      </Typography>
      <S.SelectSection>
        <S.SelectContainer>
          <MultiSelect
            disabledSelectAll={hasMoreOptionsThanSpotsAvailable}
            helperText={multiSelectHelperText}
            options={selectedTeams}
            placeholder="Select your teams"
            id="team-selection-multiselect"
            selectAllText="Select all"
            displayClearButton={false}
            primarySubtitle={
              isTabletPortaitUp
                ? 'Team name'
                : `Team name / ${teamRepLabel} name`
            }
            secondarySubtitle={
              isTabletPortaitUp ? `${teamRepLabel} name` : undefined
            }
            bottomAction={{
              ...createNewTeamOption,
              disabled:
                numberOfSpotsLeft !== undefined &&
                selectedTeams.filter(
                  (selectedTeam) => selectedTeam.checkboxState
                ).length >= numberOfSpotsLeft,
            }}
            onSelectValue={handleSelectTeam}
            onSelectAll={handleSelectAll}
            onSelectClear={() => {}}
            onTagDismiss={handleTagDismiss}
            hasError={selectError}
            errorMessage={
              hasSelected
                ? 'Please select "Add teams" before saving division'
                : 'Please add teams before saving division'
            }
            ref={selectRef}
          />
        </S.SelectContainer>
        <S.AddTeamsButtonContainer>
          <Button
            variant="outline"
            size="large"
            disabled={isAddTeamsDisabled}
            onClick={handleAddTeams}
            width="100%"
          >
            Add teams
          </Button>
        </S.AddTeamsButtonContainer>
      </S.SelectSection>
      {teamSelections.length ? (
        <S.AddedTeamsContainer>
          {teamSelections.map((selection, i) => {
            const team = teams.find((team) => team.id === selection.teamId);
            const hasDivisionsWithTeamSelected =
              data?.userRegistrations.some(
                (registration) => registration.teamId === team?.id
              ) ?? false;
            if (!team) {
              return null;
            }
            return (
              <AddedTeam
                key={team.id}
                team={team}
                isLast={i === teamSelections.length - 1}
                selectIndex={i}
                divisionId={divisionId}
                maxDivisionAgeGroup={maxDivisionAgeGroup}
                hasDivisionsWithTeamSelected={hasDivisionsWithTeamSelected}
                tournamentId={tournamentId}
                onUpdateTeam={onUpdateTeam}
                onDeleteTeam={onDeleteTeam}
                setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
                onDiscard={handleDiscardTeam}
                customFields={division.customTeamFields}
              />
            );
          })}
        </S.AddedTeamsContainer>
      ) : null}
    </S.TeamSelectionContainer>
  );
};

const AddedTeam = ({
  team,
  isLast,
  selectIndex,
  divisionId,
  maxDivisionAgeGroup,
  hasDivisionsWithTeamSelected,
  tournamentId,
  onUpdateTeam,
  onDeleteTeam,
  setHasDeleteTeamDivisionError,
  onDiscard,
  customFields,
}: {
  team: Team;
  isLast?: boolean;
  selectIndex: number;
  divisionId: string;
  maxDivisionAgeGroup: number;
  hasDivisionsWithTeamSelected: boolean;
  tournamentId: string;
  onUpdateTeam: (teamId: string, teamAgeGroup: number) => void;
  onDeleteTeam: (teamId: string) => void;
  setHasDeleteTeamDivisionError: (error: boolean) => void;
  onDiscard: (selectIndex: number) => void;
  customFields: FormField[];
}) => {
  const showIncompleteFieldsWarning = hasIncompleteRequiredFields(
    customFields,
    team
  );

  return (
    <S.AddedTeam $isLast={isLast}>
      <S.AddedTeamNameContainer>
        <S.AddedTeamName variant="ui" size="large" weight="bold">
          {team.name}
        </S.AddedTeamName>
        <Typography variant="ui" size="medium" italic>
          {team.teamRepresentative?.name}
        </Typography>
        {showIncompleteFieldsWarning ? (
          <WarningIcon variant="filled" size="large" fill="#F98A28" />
        ) : null}
      </S.AddedTeamNameContainer>
      <S.ActionIconsContainer>
        <UpdateTeamDialog
          divisionId={divisionId}
          maxDivisionAgeGroup={maxDivisionAgeGroup}
          hasDivisionsWithTeamSelected={hasDivisionsWithTeamSelected}
          onDeleteTeam={onDeleteTeam}
          onUpdateTeam={onUpdateTeam}
          team={team}
          tournamentId={tournamentId}
          setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
          trigger={
            <S.DiscardTeamButtonContainer $disabled={false}>
              <PencilSquareIcon size="large" fill="var(--secondary-500)" />
            </S.DiscardTeamButtonContainer>
          }
          customFields={customFields}
        />
        <S.DiscardTeamButtonContainer
          $disabled={false}
          onClick={() => {
            onDiscard(selectIndex);
          }}
        >
          <CloseCircleIcon
            variant="filled"
            size="large"
            fill={'var(--secondary-500)'}
          />
        </S.DiscardTeamButtonContainer>
      </S.ActionIconsContainer>
    </S.AddedTeam>
  );
};
