import { PayloadAction } from '@reduxjs/toolkit';
import { Division } from 'redux/services/types/tournament';
import { getRegistrationPeriodStatus } from 'domains/Tournaments/TournamentDetailsPage/utils/registration';
import { SubmitButtonError, DivisionCardsState } from './Wizard.types';

export type RegistrationWizardSliceState = {
  /**
   * Stores the current state of each division's registrations
   */
  divisionCardsState: DivisionCardsState;
  /**
   * When this is not null, it indicates that an error message should be shown
   * by the submit button to notify the user of an issue that must be
   * resolved.
   */
  submitButtonError: SubmitButtonError | null;
  /**
   * This is used to know when we need to scroll the page to put a specific
   * division card within view of the user.
   */
  divisionToScrollToId?: string;
};

export function getInitialDivisionCardsState({
  divisions,
  timeZone,
}: {
  divisions: Division[];
  timeZone: string;
}): DivisionCardsState {
  return divisions.reduce((acc, division) => {
    /*
     * First we check for reasons the division may be unavailable for registration
     *
     * Potential reasons:
     *   - Registration period has not started yet
     *   - Registration period has ended
     *   - Division has reached maximum capacity
     */

    const registrationPeriodStatus = getRegistrationPeriodStatus(
      division.registrationStartDate,
      division.registrationEndDate,
      timeZone
    );

    if (
      registrationPeriodStatus.state === 'before-registration-period-opens' ||
      registrationPeriodStatus.state === 'after-registration-period-ends'
    ) {
      return {
        ...acc,
        [division.id]: {
          value: 'unavailable',
          reasonUnavailable: registrationPeriodStatus.state,
        },
      };
    }

    const atCapacity =
      division.maxNumberOfTeams !== undefined &&
      division.numberOfRegisteredTeams !== undefined &&
      division.maxNumberOfTeams <= division.numberOfRegisteredTeams;

    if (atCapacity) {
      return {
        ...acc,
        [division.id]: {
          value: 'unavailable',
          reasonUnavailable: 'at-capacity',
        },
      };
    }

    /*
     * The division is available for registration.
     */

    if (
      division.registrationsForUser &&
      division.registrationsForUser.length > 0
    ) {
      // TODO: Inlude 'IN_ERROR' when we implement the ability to show the appropriate error messages
      const teamsToInclude = division.registrationsForUser.filter(
        (registration) =>
          registration.registrationStatus === 'CREATED' && !registration.deleted
      );

      if (teamsToInclude.length === 0) {
        return {
          ...acc,
          [division.id]: {
            value: 'not-selected',
          },
        };
      }

      return {
        ...acc,
        [division.id]: {
          value: 'saved',
          savedTeams: teamsToInclude.map((registration) => registration.teamId),
        },
      };
    }

    return {
      ...acc,
      [division.id]: {
        value: 'not-selected',
      },
    };
  }, {});
}

export const registrationWizardReducers = {
  selectDivision: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string }>
  ) => {
    const { divisionId } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];

    if (divisionCardState.value === 'not-selected') {
      state.divisionCardsState[divisionId] = {
        value: 'not-saved',
        teamSelections: [{ teamId: '' }],
        isShowingDivisionNotSavedMessage: false,
        isShowingSubmissionErrorMessage: false,
      };
      state.submitButtonError = null;
    } else {
      console.warn(
        `The 'selectDivision' action is only valid for a division that is in the 'not-selected' state. The current state is ${divisionCardState.value}`
      );
    }
  },
  cancelDivisionChanges: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string }>
  ) => {
    const { divisionId } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];

    if (divisionCardState.value === 'not-saved') {
      state.divisionCardsState[divisionId] = {
        value: 'not-selected',
      };
      state.submitButtonError = null;
    } else if (divisionCardState.value === 'saved-and-editing') {
      state.divisionCardsState[divisionId] = {
        value: 'saved',
        savedTeams: divisionCardState.savedTeams,
      };
      state.submitButtonError = null;
    } else {
      console.warn(
        `The 'cancel' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ${divisionCardState.value}`
      );
    }
  },
  addTeamSelect: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string }>
  ) => {
    const { divisionId } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];

    if (
      divisionCardState.value === 'not-saved' ||
      divisionCardState.value === 'saved-and-editing'
    ) {
      state.divisionCardsState[divisionId] = {
        ...divisionCardState,
        teamSelections: [...divisionCardState.teamSelections, { teamId: '' }],
      };
    } else {
      console.warn(
        `The 'addTeamSelect' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ${divisionCardState.value}`
      );
    }
  },
  discardTeamSelect: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string; selectIndex: number }>
  ) => {
    const { divisionId, selectIndex } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];

    if (
      divisionCardState.value === 'not-saved' ||
      divisionCardState.value === 'saved-and-editing'
    ) {
      if (
        selectIndex >= 0 &&
        selectIndex < divisionCardState.teamSelections.length
      ) {
        state.divisionCardsState[divisionId] = {
          ...divisionCardState,
          teamSelections: divisionCardState.teamSelections.filter(
            (selection, index) => selectIndex !== index
          ),
          isShowingDivisionNotSavedMessage: false,
        };
      } else {
        console.warn(
          `The index ${selectIndex} is not valid based on the current length of the team selections.`
        );
      }
    } else if (divisionCardState.value === 'saved') {
      const savedTeams = divisionCardState.savedTeams.filter(
        (_, index) => selectIndex !== index
      );

      if (savedTeams.length === 0) {
        state.divisionCardsState[divisionId] = {
          value: 'not-selected',
        };
      } else {
        state.divisionCardsState[divisionId] = {
          ...divisionCardState,
          savedTeams,
        };
      }
    } else {
      console.warn(
        `The 'discardTeamSelect' action is only valid for a division that is in the 'not-saved', 'saved-and-editing', and 'saved' states. The current state is ${divisionCardState.value}`
      );
    }
  },
  selectTeam: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{
      divisionId: string;
      selectIndex: number;
      teamId: string;
    }>
  ) => {
    const { divisionId, selectIndex, teamId } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];
    if (
      divisionCardState.value === 'not-saved' ||
      divisionCardState.value === 'saved-and-editing'
    ) {
      state.divisionCardsState[divisionId] = {
        ...divisionCardState,
        teamSelections: divisionCardState.teamSelections.map(
          (selection, index) => {
            return index === selectIndex ? { teamId } : selection;
          }
        ),
        isShowingDivisionNotSavedMessage: false,
      };
    } else {
      console.warn(
        `The 'selectTeam' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ${divisionCardState.value}`
      );
    }
  },
  saveDivision: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{
      divisionId: string;
      teamSelectionVisualUpdates?: boolean;
    }>
  ) => {
    const { divisionId, teamSelectionVisualUpdates } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];
    if (
      divisionCardState.value === 'not-saved' ||
      divisionCardState.value === 'saved-and-editing'
    ) {
      // Check for unfilled team selects: they prevent saving, trigger error messages
      if (
        divisionCardState.teamSelections.some((selection, i) => {
          if (teamSelectionVisualUpdates) {
            return selection.teamId === '' && i !== 0;
          }
          return selection.teamId === '';
        })
      ) {
        state.divisionCardsState[divisionId] = {
          ...divisionCardState,
          teamSelections: divisionCardState.teamSelections.map((selection) =>
            selection.teamId === '' ? { teamId: '', hasError: true } : selection
          ),
          isShowingDivisionNotSavedMessage: true,
        };
      } else {
        state.divisionCardsState[divisionId] = {
          value: 'saved',
          savedTeams: divisionCardState.teamSelections
            .filter((selection) => {
              if (teamSelectionVisualUpdates) {
                return selection.teamId !== '';
              }
              return true;
            })
            .map((selection) => selection.teamId),
        };
        state.submitButtonError = null;
      }
    } else {
      console.warn(
        `The 'saveDivision' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ${divisionCardState.value}`
      );
    }
  },
  editDivision: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string }>
  ) => {
    const { divisionId } = action.payload;
    const divisionCardState = state.divisionCardsState[divisionId];

    if (divisionCardState.value === 'saved') {
      state.divisionCardsState[divisionId] = {
        value: 'saved-and-editing',
        savedTeams: divisionCardState.savedTeams,
        teamSelections: divisionCardState.savedTeams.map((teamId) => ({
          teamId: teamId,
        })),
        isShowingDivisionNotSavedMessage: false,
        isShowingSubmissionErrorMessage: false,
      };
    } else {
      console.warn(
        `The 'edit' action is only valid for a division that is in the 'saved' state. The current state is ${divisionCardState.value}`
      );
    }
  },
  clearDivision: (
    state: RegistrationWizardSliceState,
    action: PayloadAction<{ divisionId: string }>
  ) => {
    const { divisionId } = action.payload;

    state.divisionCardsState[divisionId] = {
      value: 'not-selected',
    };
    state.submitButtonError = null;
  },
  submit: (state: RegistrationWizardSliceState) => {
    const idsOfUnsavedDivisions = getIdsOfUnsavedDivisions(
      state.divisionCardsState
    );

    if (idsOfUnsavedDivisions.length > 0) {
      state.submitButtonError = 'unsaved-divisions';
      idsOfUnsavedDivisions.forEach((divisionId, index) => {
        const divisionCardState = state.divisionCardsState[divisionId];
        // Need to check state value again for typescript to allow operation
        // on property that only occurs in those states.
        if (
          divisionCardState.value === 'not-saved' ||
          divisionCardState.value === 'saved-and-editing'
        ) {
          divisionCardState.isShowingSubmissionErrorMessage = true;
          if (index === 0) {
            state.divisionToScrollToId = divisionId;
          }
        }
      });
    } else if (noDivisionsSelected(state.divisionCardsState)) {
      state.submitButtonError = 'no-selected-divisions';
    }
  },
  clearIdToScrollTo: (state: RegistrationWizardSliceState) => {
    state.divisionToScrollToId = undefined;
  },
};

/**
 * Given the states of the division cards, returns an array of the IDs of all
 * the divisions that are in one of the "editing" states: either 'not-saved' or
 * 'saved-and-editing'.
 */
export function getIdsOfUnsavedDivisions(
  divisionCardsState: DivisionCardsState
): string[] {
  const divisionIds = Object.keys(divisionCardsState);

  return divisionIds.filter((divisionId) => {
    const divisionCardState = divisionCardsState[divisionId];

    return (
      divisionCardState.value === 'not-saved' ||
      divisionCardState.value === 'saved-and-editing'
    );
  });
}

/**
 * Given the states of the division cards, returns true if all of the division
 * cards are in the 'not-selected' state.
 */
export function noDivisionsSelected(
  divisionCardsState: DivisionCardsState
): boolean {
  const divisionIds = Object.keys(divisionCardsState);
  return divisionIds.every(
    (divisionId) => divisionCardsState[divisionId].value === 'not-selected'
  );
}

export function hasSubmissionErrors(
  divisionCardsState: DivisionCardsState
): boolean {
  return (
    getIdsOfUnsavedDivisions(divisionCardsState).length > 0 ||
    noDivisionsSelected(divisionCardsState)
  );
}
