import { DateTime } from 'luxon';
import { RegistrationType, } from '@la/types';
import { START_DATE_BEFORE_END_DATE_ERROR_MESSAGE, setDatesTimezone, } from './date';
/**
 * Calculates the number of days between two dates.
 * @param dateA Date to calculate difference from.
 * @param dateB Date to calculate difference to.
 * @returns Number of days between the two dates, rounded up to the nearest whole day.
 */
const getNumberOfDays = (dateA, dateB) => {
    return Math.ceil(dateA.diff(dateB, 'days').days);
};
/**
 * Get the registration period status given the registration start
 * and end dates.
 * @param start Start of registration period in ISO format.
 * @param end End of registration period in ISO format.
 * @param timezone The timezone that the start and end dates should be
 * considered the origin.
 * @returns `RegistrationPeriodStatus` depending on where the current date falls.
 *
 * If there is no start and end registration date, then the program is
 * considered to have an open registration period:
 * ```
 *  { state: 'no-registration-dates' }
 * ```
 * If the current date is before the registration start date:
 * ```
 * {
 *  state: 'before-registration-period-opens',
 *  numberOfDays: difference between `start` and current date, rounded up to nearest whole day
 * }
 * ```
 * if the current date is after the registration start date and
 * there is no registration end date:
 * ```
 * {
 *  state: 'after-registration-period-opens'
 * }
 * ```
 * If the current date is before the registration end date:
 * ```
 * {
 *  state: 'before-registration-period-ends',
 *  numberOfDays: difference between `end` and current date, rounded up to nearest whole day
 * }
 * ```
 */
export const getRegistrationPeriodStatus = (start, end, timezone) => {
    const currentDate = DateTime.now().setZone(timezone);
    // start and end date defined
    if (start && end) {
        let [startDate, endDate] = setDatesTimezone([start, end], timezone);
        startDate = startDate.startOf('day');
        endDate = endDate.endOf('day');
        if (startDate > endDate) {
            throw new Error(START_DATE_BEFORE_END_DATE_ERROR_MESSAGE);
        }
        if (currentDate >= startDate) {
            if (currentDate <= endDate) {
                return {
                    state: 'before-registration-period-ends',
                    numberOfDays: getNumberOfDays(endDate, currentDate),
                };
            }
            return {
                state: 'after-registration-period-ends',
            };
        }
        return {
            state: 'before-registration-period-opens',
            numberOfDays: getNumberOfDays(startDate, currentDate),
        };
    }
    // start date defined, end date undefined
    if (start && !end) {
        let [startDate] = setDatesTimezone([start], timezone);
        startDate = startDate.startOf('day');
        if (currentDate < startDate) {
            return {
                state: 'before-registration-period-opens',
                numberOfDays: getNumberOfDays(startDate, currentDate),
            };
        }
        return {
            state: 'before-registration-period-ends',
        };
    }
    // end date defined, start date undefined
    if (end && !start) {
        let [endDate] = setDatesTimezone([end], timezone);
        endDate = endDate.endOf('day');
        if (currentDate <= endDate) {
            return {
                state: 'before-registration-period-ends',
                numberOfDays: getNumberOfDays(endDate, currentDate),
            };
        }
        return {
            state: 'after-registration-period-ends',
        };
    }
    // no dates defined
    return {
        state: 'no-registration-dates',
    };
};
/**
 * Format form fields for workflow submission
 * @param formFields Form fields to be included. If a form field does not have
 * a defined `value`, it will be omitted from the return.
 * @returns Form fields formatted as objects for workflow.
 */
export const formatFormFieldsForWorkflow = (formFields) => {
    return formFields.reduce((allFields, field) => {
        var _a, _b;
        const { type, value } = field;
        const formFieldId = field.propertyDefinitionId;
        if (value) {
            if (type === 'FILE_UPLOAD') {
                if (value.uuid) {
                    return allFields.concat({
                        formFieldId,
                        type,
                        values: [value.name, value.uuid],
                    });
                }
            }
            else if (type === 'MULTIPLE_CHECKBOXES') {
                return allFields.concat({
                    formFieldId,
                    type,
                    values: value.map((value) => {
                        var _a, _b;
                        const optionValue = (_b = (_a = field.items.find((item) => item.itemId === value)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
                        return optionValue;
                    }),
                });
            }
            else if (type === 'NUMERIC') {
                // Need to check the above conditions to separate the type of value `(string | number)`
                // to just be `number` here and just string in the `else` block, otherwise
                // we get a complaint about potential mismatch between the `type` and the `values`.
                return allFields.concat({
                    formFieldId,
                    type,
                    values: [value.toString()],
                });
            }
            else if (type === 'PICK_LIST') {
                const optionValue = (_b = (_a = field.items.find((item) => item.itemId === value)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
                return allFields.concat({
                    formFieldId,
                    type,
                    values: [optionValue],
                });
            }
            else {
                return allFields.concat({
                    formFieldId,
                    type,
                    values: [value.toString()],
                });
            }
        }
        return allFields;
    }, []);
};
/**
 * Retrieves property values by name give an array of `Property`
 * @param name Name of property to retrieve
 * @param properties Properties to search through
 * @returns Values of the property if found, `undefined` otherwise
 */
export const getProperty = (name, properties) => {
    var _a;
    return (_a = properties.find((property) => property.name === name)) === null || _a === void 0 ? void 0 : _a.values;
};
export const getRegistrationsPricing = ({ registrations, divisions, paymentOptions, groupedProgramId, }) => {
    const pricing = [];
    let totalAmount = 0;
    for (const { programId, teams } of registrations) {
        const division = divisions.find((division) => division.id === programId);
        if (!division || !division.cost) {
            continue;
        }
        totalAmount += teams.length * parseFloat(division.cost);
    }
    for (const paymentOption of paymentOptions) {
        const lineItemsIterations = registrations.filter(({ teams }) => teams.length);
        const lineItems = registrations
            .filter(({ teams }) => teams.length)
            .map(({ programId }) => {
            var _a;
            const division = divisions.find((division) => division.id === programId);
            const iteration = (_a = lineItemsIterations.find((r) => r.programId === programId)) !== null && _a !== void 0 ? _a : { teams: [] };
            return Array.from({ length: iteration.teams.length }).map(() => ({
                basePrice: division && division.cost ? parseFloat(division.cost) : 0,
                currency: 'USD',
                groupedProgramId,
                subprogramId: programId,
            }));
        })
            .flat();
        const pricingItem = {
            amount: totalAmount,
            cartItemUuid: null,
            dueToday: 0,
            ngPaymentPlanId: paymentOption.paymentPlanId,
            payLater: false,
            paymentTerm: 'CARD',
            paymentType: 'CARD',
            lineItems,
        };
        pricing.push(pricingItem);
    }
    return pricing;
};
export const isPlayerRegistration = (type) => {
    if (!type) {
        return false;
    }
    const playerRoles = [
        RegistrationType.InvitePlayer,
        RegistrationType.Player,
        RegistrationType.PlayerFreeAgent,
    ];
    return playerRoles.includes(type);
};
export const isStaffRegistration = (type) => {
    if (!type) {
        return false;
    }
    const staffRoles = [
        RegistrationType.InviteStaff,
        RegistrationType.Staff,
        RegistrationType.StaffFreeAgent,
    ];
    return staffRoles.includes(type);
};
export const isTeamRegistration = (type) => {
    if (!type) {
        return false;
    }
    return type === RegistrationType.Team;
};
export const feesSettingsByRoles = {
    [RegistrationType.Team]: {
        enabled: 'team_fee_enabled',
        price: 'price_per_team',
        canChooseTeam: undefined,
        permittedRoles: undefined,
    },
    [RegistrationType.Player]: {
        enabled: 'team_player_fee_enabled',
        price: 'team_player_fee_amount',
        canChooseTeam: 'can_team_players_choose_their_team',
        permittedRoles: undefined,
    },
    [RegistrationType.PlayerFreeAgent]: {
        enabled: 'free_agent_player_fee_enabled',
        price: 'free_agent_player_fee_amount',
        canChooseTeam: undefined,
        permittedRoles: undefined,
    },
    [RegistrationType.Staff]: {
        enabled: 'team_staff_member_fee_enabled',
        price: 'team_staff_member_fee_amount',
        canChooseTeam: 'can_team_staff_members_choose_their_team',
        permittedRoles: 'team_staff_member_permitted_role_types',
    },
    [RegistrationType.StaffFreeAgent]: {
        enabled: 'free_agent_staff_member_fee_enabled',
        price: 'free_agent_staff_member_fee_amount',
        canChooseTeam: undefined,
        permittedRoles: 'free_agent_staff_member_permitted_role_types',
    },
};
