import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Badge,
  Button,
  EnvelopeIcon,
  PlusCircleIcon,
  Typography,
} from '@la/ds-ui-components';
import { getSiteId, getUserId, useAppSelector } from '@la/services';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import {
  PaymentStatus,
  Registration,
  RegistrationStatus,
  RegistrationType,
  StaffInvitation,
} from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import {
  CapacityProgressBar,
  CapacityProgressBarProps,
} from 'components/CapacityProgressBar/CapacityProgressBar';
import { useUpdateRosterMembersMutation } from 'redux/services/rosterManagementApi';
import {
  AddStaffFormFields,
  AddStaffModal,
} from './AddStaffModal/AddStaffModal';
import RosterTable, { Registrant } from './RosterTable';
import * as S from './RosterManagement.styles';

export type RosterSectionData = {
  completedRegistrationsCount: number;
  minimumCapacity?: number;
  maximumCapacity?: number;
  openAddModal?: () => void;
  openModal: () => void;
  pendingRegistrations: Registration[];
  programId: string;
  rosterData?: Registrant[];
  rosterTitle: string;
  rosterType: 'players' | 'staff';
  tableTitleSize: 'medium' | 'large';
  teamId: string;
  spotsRemaining?: number;
  staffInviteLinks?: StaffInvitation[];
  userRegistrationQueryRefetch?: () => void;
  fee: string;
};

/* RosterSection */
export default function RosterSection({
  completedRegistrationsCount,
  minimumCapacity,
  maximumCapacity,
  openAddModal = () => {},
  openModal,
  pendingRegistrations,
  programId,
  rosterData = [],
  rosterTitle,
  rosterType,
  tableTitleSize,
  teamId,
  spotsRemaining,
  staffInviteLinks,
  userRegistrationQueryRefetch,
  fee,
}: RosterSectionData) {
  const siteId = useAppSelector(getSiteId);
  const {
    registrationsRosterAddPlayer,
    registrationsRosterAddStaff,
    registrationsRosterCapacityUpdates,
  } = useFlags();

  const { subdomain } = getLAHostnameParts();
  const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);

  const [createStaff] = useUpdateRosterMembersMutation();
  const userId = useAppSelector(getUserId);

  const inviteType = rosterType === 'players' ? 'player' : 'staff';

  const pendingRegistrationsCount = rosterData.filter(
    (roster) => roster.registrationStatus !== 'SPOT_RESERVED'
  ).length;

  const iconFill =
    spotsRemaining || spotsRemaining === undefined
      ? 'var(--secondary)'
      : 'var(--blue-grey-400)';

  const getCapacityProgressBarProps = (): CapacityProgressBarProps => {
    const baseProps: Pick<
      CapacityProgressBarProps,
      'filled' | 'inviteType' | 'pending'
    > = {
      filled: completedRegistrationsCount,
      inviteType: inviteType,
      pending: pendingRegistrationsCount,
    };

    if (minimumCapacity && maximumCapacity) {
      return { ...baseProps, min: minimumCapacity, max: maximumCapacity };
    } else if (minimumCapacity && !maximumCapacity) {
      return { ...baseProps, min: minimumCapacity };
    } else if (!minimumCapacity && maximumCapacity) {
      return { ...baseProps, max: maximumCapacity };
    }

    return { ...baseProps, min: 0, max: 0 };
  };

  const onCreateStaff = (values: AddStaffFormFields) => {
    const name = `${values.firstName} ${values.lastName}`;
    const staff = {
      deleted: false,
      paymentStatus: PaymentStatus.Unpaid,
      programId: parseInt(programId),
      registeringUserId: userId,
      registrationStatus: RegistrationStatus.PendingInvite,
      siteId: Number(siteId),
      teamIdOg: parseInt(teamId),
      properties: [
        {
          name: 'email',
          values: [values.email],
        },
        {
          name: 'programStaffId',
          values: [values.roleId],
        },
        {
          name: 'primaryStaff',
          values: ['false'],
        },
        {
          name: 'staffName',
          values: [name],
        },
      ],
      registrationType: RegistrationType.InviteStaff,
    };
    createStaff({
      siteSubdomain: subdomain,
      programId: parseInt(programId),
      content: {
        add: [staff],
      },
    }).then(() => {
      setIsStaffModalOpen(false);
      if (userRegistrationQueryRefetch) {
        userRegistrationQueryRefetch();
      }
    });
  };

  return (
    <S.RosterSection>
      <S.SectionHead>
        <S.SectionHeadTitle>
          <Typography variant="headline" size={tableTitleSize}>
            {rosterTitle}
          </Typography>{' '}
          {spotsRemaining || spotsRemaining === 0 ? (
            <Badge number={spotsRemaining} size="large" text="Spots left" />
          ) : null}
        </S.SectionHeadTitle>
      </S.SectionHead>

      <S.ProgressBarContainer>
        {registrationsRosterCapacityUpdates &&
        (minimumCapacity || maximumCapacity) ? (
          <CapacityProgressBar {...getCapacityProgressBarProps()} />
        ) : null}
        {isTabletPortraitUp && inviteType === 'player' ? (
          <S.HeaderButtonsSection>
            {registrationsRosterAddPlayer ? (
              <Button
                leftIcon={
                  <PlusCircleIcon
                    fill="var(--secondary)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={openAddModal}
                size="medium"
                variant="outline"
              >
                Add player
              </Button>
            ) : null}
            <Button
              disabled={spotsRemaining !== undefined && !spotsRemaining}
              leftIcon={
                <EnvelopeIcon fill={iconFill} size="xl" variant="bold" />
              }
              onClick={openModal}
              size="medium"
              variant="outline"
            >
              Invite players
            </Button>
          </S.HeaderButtonsSection>
        ) : null}
        {isTabletPortraitUp && inviteType !== 'player' ? (
          <S.HeaderButtonsSection>
            {registrationsRosterAddStaff ? (
              <Button
                leftIcon={
                  <PlusCircleIcon
                    fill="var(--secondary)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={() => setIsStaffModalOpen(true)}
                size="medium"
                variant="outline"
              >
                Add {inviteType}
              </Button>
            ) : null}
            <Button
              disabled={spotsRemaining !== undefined && !spotsRemaining}
              leftIcon={
                <EnvelopeIcon fill={iconFill} size="xl" variant="bold" />
              }
              onClick={openModal}
              size="medium"
              variant="outline"
            >
              Get {inviteType} invite link
            </Button>
          </S.HeaderButtonsSection>
        ) : null}
      </S.ProgressBarContainer>

      {rosterData.length > 0 ? (
        <RosterTable
          pendingRegistrations={pendingRegistrations}
          programId={programId}
          registrants={rosterData}
          tableType={rosterType}
          teamId={teamId}
          fee={fee}
        />
      ) : (
        <EmptyRosterNotice
          inviteType={inviteType}
          rosterType={rosterType}
          openModal={openModal}
        />
      )}
      <AddStaffModal
        isOpen={isStaffModalOpen}
        onOpenChange={() => setIsStaffModalOpen(false)}
        staffInviteLinks={staffInviteLinks}
        onSubmit={onCreateStaff}
      />
    </S.RosterSection>
  );
}
/* */

export function EmptyRosterNotice({
  inviteType,
  rosterType,
  openModal,
}: {
  inviteType: 'player' | 'staff';
  rosterType: 'players' | 'staff';
  openModal: () => void;
}) {
  return (
    <S.EmptyRosterNotice>
      <Typography variant="headline" size="small">
        You don't have any {rosterType} in your roster.
      </Typography>
      <Typography variant="ui" size="large" weight="regular">
        Get started by inviting {rosterType}.
      </Typography>
      <Button size="large" onClick={openModal}>
        Get {inviteType} invite link
      </Button>
    </S.EmptyRosterNotice>
  );
}
