import axios from 'axios';
import { Gender } from '@la/types';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export enum AccountInfoContext {
  Admin = 'admin',
  Member = 'member',
}

export type AccountInfo = {
  user: {
    id: number;
    type: string;
    firstName: string;
    lastName: string;
    email: string;
    altEmail?: string;
    orgAccountRole: string;
    profilePicture?: string;
    birthdate?: string;
    gender?: Gender;
  };
  site: {
    id: number;
    name: string;
    mode?: string;
  };
  orgAccount: {
    id: number;
    name: string;
    status?: string;
  };
};

const baseUrl = getBaseURL();

const getAccountInfo = async ({
  context = AccountInfoContext.Member,
  siteId,
}: {
  context?: AccountInfoContext;
  siteId: string;
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/accountInfo?context=${context}`;
  const token = extractAccessToken();

  const response = await axios.get<AccountInfo>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export { getAccountInfo };
