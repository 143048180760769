import styled from 'styled-components';
import { media } from '../../lib/media-queries/mixins';
export const BottomDrawer = styled.div `
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
  border-top: 1px solid;
  border-color: var(--primary-500);
  background-color: white;
  z-index: 50;
`;
export const DrawerContent = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding: 12px 16px;

  ${media.TabletPortraitDown `
    flex-direction: column-reverse;
  `}
`;
export const ForwardButtonContainer = styled.div `
  display: flex;
  justify-content: flex-end;

  ${media.TabletPortraitDown `
    width: 100%;
    & div {
      width: 100%;
    }
  `}
`;
export const BackButtonContainer = styled.div `
  display: flex;
  justify-content: flex-end;

  ${media.TabletPortraitDown `
    width: 25%;
    & div {
      width: 100%;
    }
  `}
  & svg > polyline {
    stroke: var(--blue-grey-900);
  }
`;
export const CancelButtonContainer = styled.div `
  flex-grow: 1;
  ${media.TabletPortraitDown `
    width: 100  %;
    & div {
      width: 100%;
    }
  `}
`;
export const DrawerInnerContainer = styled.div `
  display: flex;
  gap: 8px;
`;
