import styled from 'styled-components/macro';
import { Typography } from '@la/ds-ui-components';
import { ColumnGrid } from 'components/ColumnGrid/ColumnGrid.styles';
import { media } from 'lib/media-queries/mixins';
import { PageContent } from '../../Shell/Page/Page.styles';

export const ConsolidatedConfirmationPage = styled(PageContent)`
  padding-bottom: 0;

  ${ColumnGrid} {
    row-gap: 16px;

    ${media.TabletLandscapeUp`
      row-gap: 32px;
    `}
  }
`;

export const ConfirmationHead = styled(Typography)`
  margin-bottom: 4px;
`;

export const MessageBody = styled(Typography)`
  display: inline;

  ${media.TabletPortraitUp`
    display: inline-block;
  `}
`;
