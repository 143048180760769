import { CardProps, Select, Typography } from '@la/ds-ui-components';
import { RegistrationStatus } from '@la/types';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetUserRegistrationsQuery } from 'redux/services/tournamentApi';
import { Team } from 'redux/services/types/team';
import { ReactComponent as IconPlus } from 'assets/icons/plus_circle.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import {
  DivisionNotSaved,
  DivisionSavedAndEditing,
} from '../../../Wizard.types';
import { AvailableDivisionCardProps, formatAgeGroup } from '../DivisionCard';
import { getNumberOfSpotsLeft } from '../utils/capacity';
import { UpdateTeamDialog, UpdateTeamDialogProps } from './UpdateTeamDialog';
import { DivisionCardSection } from '../DivisionCard.styles';
import * as S from './DivisionCardTeamSelectionSection.styles';

export const TEAM_SELECT_PLACEHOLDER_OPTION_TEXT = 'Select or create a team';
export const EMPTY_SELECT_ERROR_MESSAGE =
  'Team not selected. Select a team or discard it.';
export const DIVISION_NOT_SAVED_ALERT_MESSAGE =
  'Division not saved. One or more team selections were left blank.';

type DivisionCardTeamSelectsProps = {
  cardState: DivisionNotSaved | DivisionSavedAndEditing;
  openCreateTeamDialog: (selectIndex: number) => void;
  openDiscardConfirmationDialog: () => void;
  teamsList: Team[];
  tournamentId: string;
  setHasDeleteTeamDivisionError: (error: boolean) => void;
} & Pick<
  AvailableDivisionCardProps,
  | 'division'
  | 'divisionCardsState'
  | 'maxDivisionAgeGroup'
  | 'onClear'
  | 'onDeleteTeam'
  | 'onDiscardTeamSelect'
  | 'onSelectTeam'
  | 'tournamentId'
> &
  Pick<UpdateTeamDialogProps, 'onUpdateTeam'>;

function DivisionCardTeamSelects({
  cardState,
  division,
  maxDivisionAgeGroup,
  onClear,
  onDeleteTeam,
  onDiscardTeamSelect,
  onSelectTeam,
  onUpdateTeam,
  openCreateTeamDialog,
  openDiscardConfirmationDialog,
  teamsList,
  tournamentId,
  setHasDeleteTeamDivisionError,
}: DivisionCardTeamSelectsProps) {
  const divisionId = division.id;

  const { subdomain } = getLAHostnameParts();

  const { data } = useGetUserRegistrationsQuery({
    siteDomain: subdomain,
    programId: division.id,
  });

  const createNewTeamOption = {
    label: '+ Create new team',
    value: 'create-new-team',
  };

  const { teamSelections } = cardState;

  const registeredTeams = division.registrationsForUser
    ?.filter(
      (registration) =>
        registration.registrationStatus === RegistrationStatus.Registered
    )
    .map((registration) => registration.teamId);

  return (
    <>
      {teamSelections.map((teamSelection, selectIndex) => {
        const { teamId, hasError } = teamSelection;
        const team = teamsList.find((team) => team.id === teamId);
        const selectOptions = [
          ...teamsList.map((team) => {
            return {
              label: getTeamOptionLabel(team, registeredTeams),
              value: team.id,
              disabled:
                (teamSelections.find(
                  (teamSelectionInner) => teamSelectionInner.teamId === team.id
                ) &&
                  team.id !== teamId) ||
                (registeredTeams && registeredTeams.includes(team.id)),
            };
          }),
          createNewTeamOption,
        ];

        const onSelectChange = (newValue: string) => {
          if (newValue === createNewTeamOption.value) {
            openCreateTeamDialog(selectIndex);
          } else {
            onSelectTeam(selectIndex, newValue);
          }
        };

        const onDiscard = () => {
          if (teamSelections.length === 1) {
            if (cardState.value === 'saved-and-editing') {
              openDiscardConfirmationDialog();
            } else {
              onClear();
            }
          } else {
            onDiscardTeamSelect(selectIndex);
          }
        };

        const selectId = getTeamSelectId(divisionId, selectIndex);
        const errorMessageId = `${selectId}-error-message`;
        const label = `Team ${selectIndex + 1}`;
        const teamName = selectOptions.find(
          (option) => option.value === teamId
        )?.label;

        const hasDivisionsWithTeamSelected =
          data?.userRegistrations.some(
            (registration) => registration.teamId === teamId
          ) ?? false;

        return (
          <S.DivisionCardTeamSelection key={selectIndex}>
            <S.DivisionCardTeamSelectionTopRow>
              <Select
                id={selectId}
                label={label}
                options={selectOptions}
                placeholder={TEAM_SELECT_PLACEHOLDER_OPTION_TEXT}
                value={teamId}
                onChange={onSelectChange}
                hasError={hasError}
                aria-describedby={errorMessageId}
              />
              {team ? (
                <UpdateTeamDialog
                  divisionId={divisionId}
                  maxDivisionAgeGroup={maxDivisionAgeGroup}
                  hasDivisionsWithTeamSelected={hasDivisionsWithTeamSelected}
                  onDeleteTeam={onDeleteTeam}
                  onUpdateTeam={onUpdateTeam}
                  team={team}
                  tournamentId={tournamentId}
                  setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
                  customFields={division.customTeamFields}
                />
              ) : null}
              <S.DiscardTeamButton
                // Specify which team is being discarded when a team is selected
                ariaLabel={
                  teamName
                    ? `Discard team ${teamName}`
                    : `Discard team ${selectIndex + 1}`
                }
                variant="text"
                icon={<IconTrash />}
                onClick={onDiscard}
              />
            </S.DivisionCardTeamSelectionTopRow>
            {hasError ? (
              <S.DivisionCardTeamSelectionErrorMessage
                id={errorMessageId}
                data-testid={errorMessageId}
              >
                {EMPTY_SELECT_ERROR_MESSAGE}
              </S.DivisionCardTeamSelectionErrorMessage>
            ) : null}
          </S.DivisionCardTeamSelection>
        );
      })}
    </>
  );
}

type DivisionCardTeamSelectionSectionProps = Pick<
  AvailableDivisionCardProps,
  | 'division'
  | 'divisionCardsState'
  | 'maxDivisionAgeGroup'
  | 'onAddTeamSelect'
  | 'onClear'
  | 'onDeleteTeam'
  | 'onDiscardTeamSelect'
  | 'onSelectTeam'
  | 'tournamentId'
  | 'setHasDeleteTeamDivisionError'
> &
  Pick<CardProps, 'variant'> & {
    cardId: string;
    cardState: DivisionNotSaved | DivisionSavedAndEditing;
    teamsList: Team[];
    openCreateTeamDialog: (selectIndex: number) => void;
    openDiscardConfirmationDialog: () => void;
  } & Pick<UpdateTeamDialogProps, 'onUpdateTeam'>;

export function DivisionCardTeamSelectionSection({
  cardId,
  cardState,
  division,
  divisionCardsState,
  maxDivisionAgeGroup,
  onAddTeamSelect,
  onClear,
  onDeleteTeam,
  onDiscardTeamSelect,
  onSelectTeam,
  onUpdateTeam,
  openCreateTeamDialog,
  openDiscardConfirmationDialog,
  teamsList,
  tournamentId,
  variant,
  setHasDeleteTeamDivisionError,
}: DivisionCardTeamSelectionSectionProps) {
  const addTeamSelectButtonId = `${cardId}-add-team-select`;
  const numberOfSpotsLeft = getNumberOfSpotsLeft(division);

  const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletLandscapeUp);

  return (
    <DivisionCardSection $variant={variant}>
      <S.DivisionCardTeams>
        <Typography
          size={isTabletPortraitUp ? 'large' : 'medium'}
          variant="ui"
          weight="bold"
        >
          Add your teams
        </Typography>
        {cardState.isShowingDivisionNotSavedMessage ? (
          <S.DivisionCardTeamSelectionAlertMessage role="alert">
            {DIVISION_NOT_SAVED_ALERT_MESSAGE}
          </S.DivisionCardTeamSelectionAlertMessage>
        ) : null}
        <DivisionCardTeamSelects
          cardState={cardState}
          division={division}
          divisionCardsState={divisionCardsState}
          maxDivisionAgeGroup={maxDivisionAgeGroup}
          onClear={onClear}
          onDeleteTeam={onDeleteTeam}
          onUpdateTeam={onUpdateTeam}
          onDiscardTeamSelect={onDiscardTeamSelect}
          onSelectTeam={onSelectTeam}
          openCreateTeamDialog={openCreateTeamDialog}
          openDiscardConfirmationDialog={openDiscardConfirmationDialog}
          teamsList={teamsList}
          tournamentId={tournamentId}
          setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
        />
        {!!numberOfSpotsLeft &&
        cardState.teamSelections.length >= numberOfSpotsLeft ? (
          <S.DivisionSpotsLeftMessage size="medium" variant="ui" weight="bold">
            {numberOfSpotsLeft <= 0
              ? 'Division has no spots left'
              : `Division only has ${numberOfSpotsLeft === 1 ? '1 spot' : `${numberOfSpotsLeft} spots`} left`}
          </S.DivisionSpotsLeftMessage>
        ) : (
          <S.DivisionCardAddTeamSelectButton
            id={addTeamSelectButtonId}
            data-testid={addTeamSelectButtonId}
            variant="text"
            leftIcon={<IconPlus />}
            onClick={onAddTeamSelect}
            size="medium"
          >
            Add another team
          </S.DivisionCardAddTeamSelectButton>
        )}
      </S.DivisionCardTeams>
    </DivisionCardSection>
  );
}

/**
 * Returns the option label for a team that includes the name and age group
 * in order to help the user distinguish which team they are selecting.
 *
 * @param team The team to get the option label parts from
 * @returns A string in the format of <team name> (<age group>)
 */
export function getTeamOptionLabel(
  team: Team,
  registeredTeams?: (string | undefined | null)[]
): string {
  const ageGroup =
    team.ageGroup === 0 ? 'All ages' : formatAgeGroup(team.ageGroup);

  const draftText = team.status === 'DRAFT' ? ' (draft)' : '';
  const registeredText =
    registeredTeams && registeredTeams.includes(team.id ?? '')
      ? '(Registered)'
      : '';

  return `${team.name} (${ageGroup})${draftText}${registeredText}`;
}

/**
 * Each team select needs a unique ID. This creates one using the division ID and
 * the 1-indexed "team number" as it is displayed as the select label in the UI.
 *
 * @param division The tournament division
 * @param selectIndex The array index of the select
 * @returns A string to use as the ID of a team select
 */
export function getTeamSelectId(divisionId: string, selectIndex: number) {
  return `division-${divisionId}-team-select-${selectIndex + 1}`;
}
