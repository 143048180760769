import styled from 'styled-components';

export const RegistrationReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 80px;
  max-width: 920px;
  width: 100%;
`;

export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12;
`;

export const FieldEntry = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WaiversContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
