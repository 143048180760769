import clsx from 'clsx';
import { Typography } from '@la/ds-ui-components';
import { RegisteredRegistrationType } from '@la/types';
import styles from './RegistrationTypeItem.module.scss';

type RegistrationTypeItemProps = {
  type: RegisteredRegistrationType;
  title: string;
  subtitle: string;
  price: string;
  selectedType: RegisteredRegistrationType | null;
  setSelectedType: (type: RegisteredRegistrationType | null) => void;
  disabled: boolean;
};

export const RegistrationTypeItem = ({
  type,
  selectedType,
  setSelectedType,
  title,
  subtitle,
  price,
  disabled,
}: RegistrationTypeItemProps) => {
  const containerStyles = clsx({
    [styles.container]: true,
    [styles.disabledContainer]: disabled,
  });
  return (
    <div
      className={containerStyles}
      onClick={() => {
        if (!disabled) {
          setSelectedType(type !== selectedType ? type : null);
        }
      }}
    >
      <div className={styles.header}>
        <div className={styles.radioContainer}>
          <input
            type="radio"
            id={type}
            className={styles.radioInput}
            checked={type === selectedType}
            onChange={(e) => {
              const value = e.target.value === 'on' ? type : null;
              setSelectedType(value);
            }}
            disabled={disabled}
          />
          <Typography variant="ui" size="large" weight="bold" customTag="label">
            {title}
          </Typography>
        </div>
        <div>
          <Typography variant="ui" size="medium" weight="bold">
            {price}
          </Typography>
        </div>
      </div>
      <Typography variant="ui" size="medium" className={styles.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};
