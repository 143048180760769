import { RegisteredRegistrationType, RegistrationType } from '@la/types';
import { SelectRegistrationFields } from './SelectRegistrationType.types';

export const registrationTypesTitles: Record<
  RegisteredRegistrationType,
  string
> = {
  [RegistrationType.Team]: 'Team',
  [RegistrationType.Player]: 'Team Player',
  [RegistrationType.PlayerFreeAgent]: 'Free Agent Player',
  [RegistrationType.Staff]: 'Team Staff Member',
  [RegistrationType.StaffFreeAgent]: 'Free Agent Staff Member',
};

export const registrationTypesSubtitles: Record<
  RegisteredRegistrationType,
  string
> = {
  [RegistrationType.Team]: 'Register your team to compete in this tournament.',
  [RegistrationType.Player]: 'Register as a player and select a team to join.',
  [RegistrationType.PlayerFreeAgent]:
    'Register as a player and be added to a team later.',
  [RegistrationType.Staff]:
    'Register as a staff member and select a team to join.',
  [RegistrationType.StaffFreeAgent]:
    'Register as a staff member and be added to a team later.',
};

export const registrationTypesAdditionalInfoTitle: Record<
  RegisteredRegistrationType,
  string
> = {
  [RegistrationType.Team]: '',
  [RegistrationType.Player]: 'Choose your program and team',
  [RegistrationType.PlayerFreeAgent]: 'Choose your program',
  [RegistrationType.Staff]: 'Choose your program, team, and role',
  [RegistrationType.StaffFreeAgent]: 'Choose your program and role',
};

export const additionalInfoFields: Record<
  RegisteredRegistrationType,
  (keyof SelectRegistrationFields)[]
> = {
  [RegistrationType.Team]: [],
  [RegistrationType.Player]: ['selectedDivision', 'selectedTeam'],
  [RegistrationType.PlayerFreeAgent]: ['selectedDivision'],
  [RegistrationType.Staff]: [
    'selectedDivision',
    'selectedTeam',
    'selectedStaffRole',
  ],
  [RegistrationType.StaffFreeAgent]: ['selectedDivision', 'selectedStaffRole'],
};
